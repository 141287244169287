import { useIntl } from 'react-intl';
import { GoTriangleDown } from 'react-icons/go';
import { HiSearch } from 'react-icons/hi';
import { ChatItem } from '.';
import { Chat } from '../types';
import styles from './ChatList.module.scss';
import sharedStyles from '../Messenger.module.scss';

type Props = {
  chats: Chat[]
  handleChatChange: (id: string) => void
}

export const ChatList = ({ chats, handleChatChange }: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <>
      <div className={sharedStyles.header}>
        <p className={sharedStyles.headerText}>
          { f({ id: 'messenger.inbox' }) }
        </p>
        <div className={sharedStyles.searchContainer}>
          <input
            className={sharedStyles.input}
            type="text"
            placeholder={f({ id: 'messenger.placeholder.search' })}
          />
          <HiSearch className={sharedStyles.suffix} size={24} />
        </div>
      </div>
      <div className={styles.container}>
        { chats.map((chat) => (
          <ChatItem
            chat={chat}
            key={chat.id}
            onClick={() => handleChatChange(chat.id)}
          />
        )) }

        <div className={styles.showMoreContainer}>
          <button type="button" className={styles.showMoreButton}>
            <p>{ f({ id: 'messenger.showMoreChats' }) }</p>
            <GoTriangleDown />
          </button>
        </div>
      </div>
    </>
  );
};
