import { useState } from 'react';
import { useField } from 'formik';
import { GoSettings } from 'react-icons/go';

import { useIntl } from 'react-intl';
import { Chip } from '../../../components/UI';
import { useOutsideDiv } from '../../../core/hooks';
import styles from '../Discover.module.scss';

const options = ['byFollowers', 'byLikes'] as const;

type Props = {
  handleSubmit: () => void;
};

export const SortBy = ({ handleSubmit }: Props) => {
  const { formatMessage: f } = useIntl();
  const [isExpand, setIsExpand] = useState(false);
  const [field, , helpers] = useField('sortBy');

  const handleOnExpand = () => {
    setIsExpand((prevState) => !prevState);
  };

  const handleClose = () => {
    setIsExpand(false);
  };

  const ref = useOutsideDiv(handleClose);

  const handleOnClick = (value: string) => {
    const currentValue = field.value;

    if (currentValue === value) {
      helpers.setValue('');
    } else {
      helpers.setValue(value);
    }

    handleSubmit();
  };

  return (
    <div ref={ref} className={styles.sortByContainer}>
      <Chip
        clicked={isExpand}
        onClick={handleOnExpand}
        chipStyle={styles.button}
      >
        <GoSettings className={styles.buttonIcon} />
        { ' ' }
        { f({ id: 'discover.sortBy' }) }
      </Chip>

      { isExpand && (
        <div className={styles.expandedContainer}>
          { options.map((option) => (
            <Chip
              key={option}
              clicked={field.value === option}
              onClick={() => handleOnClick(option)}
              chipStyle={styles.chip}
            >
              { f({ id: `discover.sortBy.${option}` }) }
            </Chip>
          )) }
        </div>
      ) }
    </div>
  );
};
