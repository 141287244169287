import { useState } from 'react';
import { AiOutlineInstagram } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button, ErrorScreen, LoadingScreen, TitleText,
} from '../../components/UI';

import { formatCompactNumber } from '../../core/helpers';
import { CompanyBasicInfo, Opinions } from './components';
import styles from './CompanyProfile.module.scss';
import { FetchProfileQuery, useFetchProfileQuery } from '../../generated/graphql';
import { useUser } from '../../core/providers/User';
import { parseToBackendId } from '../../core/helpers/idParsers';
import { FavButton, ProfilePhoto } from '../../components/userProfile';

// const companyData = {
//   fullName: 'My Booker - Mobile modeling agency',
//   city: 'Częstochowa',
//   country: 'Polska',
//   achievements: [
//     'Certyfikat najlepszej firmy wszechczasów',
//     'Młody i dynamiczny zespół',
//   ],
//   description:
//     'Lorem ipsum dolor,
//   instagramFollowers: 5342,
// };

type Props = {
  myProfile?: boolean
}
export type ClientProfileType = Extract<FetchProfileQuery['profile'], { __typename: 'ClientProfile' }>;
type ProfileT = FetchProfileQuery['profile'];

export const CompanyProfile = ({ myProfile = false }: Props) => {
  const { formatMessage: f } = useIntl();
  const { user } = useUser();
  const [isFav, setIsFav] = useState(false);
  const [apiError, setApiError] = useState('');

  const params = useParams();

  const handleOnFav = () => {
    setIsFav((prevState) => !prevState);
  };

  const { data, loading, error } = useFetchProfileQuery({
    variables: {
      profileId: (myProfile && user && user.profile) ? user.profile.id : parseToBackendId('client', params.companyId),
    },
    onError: ({ message }) => {
      setApiError(message);
      toast.error(message);
    },
  });

  // const companyData = data?.profile;

  // const displayedFollowersNumber = formatCompactNumber(
  //   companyData.instagramFollowers,
  // );

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorScreen errorMessage={apiError} />;
  }

  const isClientProfile = (profile: ProfileT): profile is ClientProfileType => {
    // eslint-disable-next-line no-underscore-dangle
    return profile.__typename === 'ClientProfile';
  };

  const { profile } = data;

  if (!isClientProfile(profile)) {
    toast.error('Coś poszło nie tak');
    return <Navigate to="/" />;
  }

  return (
    <div>
      <div className={styles.firstSection}>
        <div className={styles.profileImgContainer}>
          <ProfilePhoto profilePhoto={profile.profilePhoto} />
          <FavButton isFav={isFav} onClick={handleOnFav} black={!profile.profilePhoto} />
        </div>

        <div className={styles.basicInfoContainer}>
          <TitleText className={`${styles.detailsText} ${styles.name}`}>
            { profile.name }
          </TitleText>

          <CompanyBasicInfo companyProfile={profile} />

          <div className={styles.detailsContainer}>
            { /* <div className={styles.followersContainer}>
              <AiOutlineInstagram size={50} className={styles.instagramIcon} />
              <TitleText>{ displayedFollowersNumber }</TitleText>
            </div> */ }

            <div className={styles.buttonsContainer}>
              <Button>{ f({ id: 'companyProfile.sendMessage' }) }</Button>
              <Button>{ f({ id: 'companyProfile.offer' }) }</Button>
            </div>
          </div>
        </div>
      </div>

      <Opinions />
    </div>
  );
};
