import { useState } from 'react';
import { Slider, Rail, Handles } from 'react-compound-slider';
import { useField, useFormikContext } from 'formik';
import { BarChart } from './components/BarChart';

import { RailComponent, HandleComponent } from './components/components';

import styles from './Range.module.scss';
import RangeInput from './components/RangeInput';
import { Button } from '../../Button/Button/Button';

const data: number[] = [];
for (let i = 0; i < 500; i += 1) {
  data.push(Math.floor(Math.random() * 100));
}
data.push(99, 99, 99);

export type RangeProps = {
  name: string
  handleCollapse?: () => void
}

export const Range = ({ name, handleCollapse }: RangeProps) => {
  const [field, , helpers] = useField(name);
  const { submitForm } = useFormikContext();
  const sortedData = data.slice().sort((a, b) => a - b);
  const range = [sortedData[0], sortedData[sortedData.length - 1]];

  const [rangeData, setRangeData] = useState({
    domain: range,
    update: field.value,
    values: range,
    inputValues: field.value,
  });

  const {
    domain, update, values, inputValues,
  } = rangeData;

  const handleReset = () => {
    helpers.setValue(range);
    setRangeData((prevData) => ({
      ...prevData,
      update: range,
      inputValues: range,
    }));
    handleSubmit();
  };

  const handleSubmit = () => {
    submitForm();
    if (handleCollapse) {
      handleCollapse();
    }
  };

  return (
    <div className={styles.container}>
      <BarChart data={data} highlight={update} domain={domain as [number, number]} />
      <Slider
        mode={3}
        step={1}
        domain={domain}
        rootStyle={{
          position: 'relative',
          width: '250px',
        }}
        onUpdate={(upd) => {
          setRangeData((prev) => ({
            ...prev,
            update: upd,
            inputValues: upd,
          }));
          helpers.setValue(upd);
        }}
        onChange={(val) => setRangeData((prev) => ({ ...prev, values: val as number[] }))}
        values={values}
      >
        <Rail>
          { ({ getRailProps }) => <RailComponent getRailProps={getRailProps} /> }
        </Rail>
        <Handles>
          { ({ handles, getHandleProps }) => (
            <div style={{ width: '270px' }}>
              { handles.map((handle: { id: string }, index: number) => (
                <HandleComponent
                  key={handle.id}
                  handle={{
                    ...handle,
                    value: field.value[index],
                    percent: field.value[index],
                  }}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              )) }
            </div>
          ) }
        </Handles>
      </Slider>
      <div className={styles.inputsContainer}>
        <RangeInput
          name="min"
          value={inputValues[0]}
          onChange={(evt) => {
            const { value } = evt.target;
            const newState = [value, inputValues[1]];
            setRangeData((prev) => ({ ...prev, inputValues: newState }));
            if (value && Number(value) >= domain[0]) {
              setRangeData((prev) => ({ ...prev, values: newState }));
            }
          }}
        />
        <div>—</div>
        <RangeInput
          name="max"
          value={inputValues[1]}
          onChange={(evt) => {
            const { value } = evt.target;
            const newState = [inputValues[0], value];
            setRangeData((prev) => ({ ...prev, inputValues: newState }));
            if (
              value && Number(value) <= domain[1] && Number(value) >= values[0]
            ) {
              setRangeData((prev) => ({ ...prev, values: newState }));
            }
          }}
        />
      </div>
      <div className={`${styles.inputsContainer} ${styles.largeScreen}`}>
        <Button buttonStyle={styles.resetButton} onClick={handleReset}>
          Wyczyść
        </Button>
        <Button buttonStyle={styles.submitButton} onClick={handleSubmit}>
          Zapisz
        </Button>
      </div>
    </div>
  );
};
