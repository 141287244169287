import { useField } from 'formik';
import styles from './Checkbox.module.scss';

type Props = {
  isChecked?: boolean
  name: string
  label?: string
  size?: number
  form?: string
  isError?: boolean
  onClick: (value: string) => void
}

export const Checkbox = ({
  isChecked,
  name,
  label,
  form,
  size,
  isError,
  onClick,
}: Props) => {
  let sizeContainer;
  let sizeInner;

  if (size) {
    sizeContainer = {
      height: `${size}px`,
      width: `${size}px`,
    };

    sizeInner = {
      height: `${size - 8}px`,
      width: `${size - 8}px`,
    };
  }

  let formCtx;
  if (form) {
    const [field, meta, helpers] = useField(name);
    formCtx = {
      error: meta.error,
      touched: meta.touched,
      props: {
        onClick: () => helpers.setValue(!field.value),
        onBlur: () => helpers.setTouched(true),
        value: field.value,
      },
    };
  }

  const handleClick = form ? formCtx?.props.onClick : () => onClick(name);

  const checked = form ? formCtx?.props.value : isChecked;

  const shouldShowError = (form && formCtx?.error && formCtx?.touched) || (!form && isError);

  return (
    <button type="button" className={styles.container} onClick={handleClick}>
      <div
        className={`${styles.checkboxContainer} ${
          shouldShowError && styles.error
        }`}
        style={sizeContainer}
      >
        <div
          className={`${styles.innerCheckbox} ${checked && styles.checked}`}
          style={sizeInner}
        />
      </div>
      <p className={styles.label}>{ label }</p>
    </button>
  );
};
