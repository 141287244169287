import { FilterItem } from '.';
import { Range, ChipsContainer } from '../../../components/UI';

import styles from './FiltersAndCategories.module.scss';

const eyeColors = ['blue', 'green', 'red', 'black'];

export const Filters = () => {
  return (
    <div className={styles.filtersContainer}>
      <FilterItem name="height">
        <Range name="height" />
      </FilterItem>
      <FilterItem name="hips">
        <Range name="hips" />
      </FilterItem>
      <FilterItem name="waist">
        <Range name="waist" />
      </FilterItem>
      <FilterItem name="eyeColor">
        <ChipsContainer name="eyeColor" data={eyeColors} />
      </FilterItem>
      <FilterItem name="hairColor">
        <ChipsContainer name="hairColor" data={eyeColors} />
      </FilterItem>
      <FilterItem name="shoeSize">
        <Range name="shoeSize" />
      </FilterItem>
    </div>
  );
};
