import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import imageSide from '~/images/2.png';
import imageFrame1 from '~/images/1.png';
import imageFrame2 from '~/images/3.png';
import imageFrame3 from '~/images/4.png';
import sharedStyles from './sharedStyles.module.scss';
import styles from './IntroduceSection.module.scss';
import { Button, TextWithBg } from '../../../components/UI';

export const IntroduceSection = () => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/discover');
  };

  return (
    <section
      className={`${sharedStyles.sectionContainer} ${styles.sectionContainer}`}
    >
      <div className={sharedStyles.sectionItem}>
        <img
          className={`${sharedStyles.sectionImage} ${styles.sectionImage}`}
          src={imageSide}
          alt=""
        />
      </div>
      <div className={`${sharedStyles.sectionItem} ${styles.sectionTextItem}`}>
        <header>
          <TextWithBg>
            <p className={sharedStyles.headerText}>
              { f({ id: 'home.introduceSection.title.meet' }) }
            </p>
            <strong
              className={`${sharedStyles.headerText} ${sharedStyles.headerBoldText}`}
            >
              { f({ id: 'home.introduceSection.title.company' }) }
            </strong>
          </TextWithBg>
        </header>
        <div className={sharedStyles.sectionTextContainer}>
          <p className={sharedStyles.sectionText}>
            { f({ id: 'home.introduceSection.paragraph.first' }) }
          </p>
          <p className={sharedStyles.sectionText}>
            { f({ id: 'home.introduceSection.paragraph.second' }) }
          </p>
        </div>
        <Button onClick={handleNavigate}>
          { f({ id: 'home.introduceSection.seeMore' }) }
        </Button>
        <div className={styles.photosContainer}>
          <img
            className={styles.photoItem}
            src={imageFrame1}
            alt=""
          />
          <img
            className={styles.photoItem}
            src={imageFrame2}
            alt=""
          />
          <img
            className={styles.photoItem}
            src={imageFrame3}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};
