import { GetHandleProps, GetRailProps } from 'react-compound-slider';
import styles from './components.module.scss';

type RailComponentProps = {
  getRailProps: GetRailProps
}

export const RailComponent = ({ getRailProps }: RailComponentProps) => {
  return (
    <>
      <div className={styles.railHotspot} {...getRailProps()} />
      <div className={styles.rail} />
    </>
  );
};

type HandleComponentProps = {
  domain: number[]
  handle: {
    id: string
    value: number
    percent: number
  }
  getHandleProps: GetHandleProps
}

export const HandleComponent = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}: HandleComponentProps) => {
  return (
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      className={styles.handleComponent}
      style={{ left: `calc(${percent}% - 10px)` }}
      {...getHandleProps(id)}
    />
  );
};
