import { useField, useFormikContext } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Chip } from '..';
import styles from './ChipsContainer.module.scss';

export type ChipsContainerProps = {
  name: string;
  data: string[];
  handleCollapse?: () => void;
};

export const ChipsContainer = ({
  name,
  data,
  handleCollapse,
}: ChipsContainerProps) => {
  const { formatMessage: f } = useIntl();
  const [field, , helpers] = useField(name);
  const { submitForm } = useFormikContext();

  const dataObject = data.reduce(
    (prev, key) => ({ ...prev, [key]: field.value.includes(key) }),
    {},
  );

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>(dataObject);

  const handleOnItemClick = (item: keyof typeof selectedItems) => {
    const updatedSelectedItems = {
      ...selectedItems,
      [item]: !selectedItems[item],
    };
    setSelectedItems(updatedSelectedItems);
    const fieldCategories = Object.keys(updatedSelectedItems).filter(
      (key) => updatedSelectedItems[key],
    );
    helpers.setValue(fieldCategories);
  };

  const handleReset = () => {
    const updatedItems = data.reduce(
      (prev, key) => ({ ...prev, [key]: false }),
      {},
    );

    setSelectedItems(updatedItems);
    helpers.setValue([]);
    handleSubmit();
  };

  const handleSubmit = () => {
    submitForm();
    if (handleCollapse) {
      handleCollapse();
    }
  };

  return (
    <>
      <div className={styles.chipsContainer}>
        { data.map((item) => (
          <Chip
            key={item}
            clicked={selectedItems[item]}
            onClick={() => handleOnItemClick(item)}
            chipStyle={styles.chipItem}
          >
            { f({ id: `discover.filters.${name}.${item}` }) }
          </Chip>
        )) }
      </div>
      <div className={`${styles.inputsContainer} ${styles.largeScreen}`}>
        <Button buttonStyle={styles.resetButton} onClick={handleReset}>
          Wyczyść
        </Button>
        <Button buttonStyle={styles.submitButton} onClick={handleSubmit}>
          Zapisz
        </Button>
      </div>
    </>
  );
};
