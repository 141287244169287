import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

export enum AccountTypeEnum {
  Client = 'CLIENT',
  Model = 'MODEL'
}

/** Autogenerated input type of AttachToProfile */
export type AttachToProfileInput = {
  /** The type of the attachment (under what category it will be seen on the Profile) */
  attachmentType: ImageTypeEnum;
  /** Signed blob ID generated via `createDirectUpload` mutation */
  blobId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AttachToProfile. */
export type AttachToProfilePayload = {
  __typename?: 'AttachToProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  user?: Maybe<User>;
};

export type Booking = {
  __typename?: 'Booking';
  accepted: Scalars['Boolean'];
  bookingType: BookingTypeEnum;
  clientProfile: ClientProfile;
  clientProfileId: Scalars['ID'];
  companyName?: Maybe<Scalars['String']>;
  contraindications?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  date: Scalars['ISO8601Date'];
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  endHour?: Maybe<Scalars['ISO8601DateTime']>;
  hours: Scalars['Int'];
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  modelProfile: ModelProfile;
  modelProfileId: Scalars['ID'];
  projectName?: Maybe<Scalars['String']>;
  rights?: Maybe<Scalars['String']>;
  startHour?: Maybe<Scalars['ISO8601DateTime']>;
  status: BookingStatusEnum;
  updatedAt: Scalars['ISO8601DateTime'];
  wage?: Maybe<Scalars['Int']>;
  workHours?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of BookingAccept */
export type BookingAcceptInput = {
  /** The ID of the booking to Accept */
  bookingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of BookingAccept. */
export type BookingAcceptPayload = {
  __typename?: 'BookingAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

/** The connection type for Booking. */
export type BookingConnection = {
  __typename?: 'BookingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingEdge>>>;
  /** A list of nodes. */
  nodes: Array<Booking>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of BookingCreate */
export type BookingCreateInput = {
  bookingType: BookingTypeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contraindications?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  description?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  endHour?: InputMaybe<Scalars['ISO8601DateTime']>;
  hours?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  /** The ID of profile to book */
  profileId: Scalars['ID'];
  projectName?: InputMaybe<Scalars['String']>;
  rights?: InputMaybe<Scalars['String']>;
  startHour?: InputMaybe<Scalars['ISO8601DateTime']>;
  wage: Scalars['Int'];
};

/** Autogenerated return type of BookingCreate. */
export type BookingCreatePayload = {
  __typename?: 'BookingCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

/** An edge in a connection. */
export type BookingEdge = {
  __typename?: 'BookingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Booking>;
};

/** Autogenerated input type of BookingPay */
export type BookingPayInput = {
  /** The ID of the booking to Accept */
  bookingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of BookingPay. */
export type BookingPayPayload = {
  __typename?: 'BookingPayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

/** Booking status */
export enum BookingStatusEnum {
  /** Awaiting Payment booking status */
  AwaitingPayment = 'AWAITING_PAYMENT',
  /** Complete booking status */
  Complete = 'COMPLETE',
  /** Request booking status */
  Request = 'REQUEST'
}

/** Booking type */
export enum BookingTypeEnum {
  /** Casting booking type */
  Casting = 'CASTING',
  /** Direct Booking booking type */
  DirectBooking = 'DIRECT_BOOKING',
  /** Option booking type */
  Option = 'OPTION'
}

export type Category = {
  __typename?: 'Category';
  label: Scalars['String'];
  name: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
};

export type ClientProfile = {
  __typename?: 'ClientProfile';
  bio?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images: Array<Image>;
  legalName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  user: User;
  website?: Maybe<Scalars['String']>;
};

/** The connection type for ClientProfile. */
export type ClientProfileConnection = {
  __typename?: 'ClientProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientProfileEdge>>>;
  /** A list of nodes. */
  nodes: Array<ClientProfile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ClientProfileEdge = {
  __typename?: 'ClientProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ClientProfile>;
};

/** Autogenerated input type of ClientProfileUpdate */
export type ClientProfileUpdateInput = {
  bio?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of ClientProfileUpdate. */
export type ClientProfileUpdatePayload = {
  __typename?: 'ClientProfileUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientProfile: ClientProfile;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated input type of CreateDirectUpload */
export type CreateDirectUploadInput = {
  /** File size (bytes) */
  byteSize: Scalars['Int'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** File content type */
  contentType: Scalars['String'];
  /** Original file name */
  filename: Scalars['String'];
};

/** Autogenerated return type of CreateDirectUpload. */
export type CreateDirectUploadPayload = {
  __typename?: 'CreateDirectUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  directUpload: DirectUpload;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

export type Credential = {
  __typename?: 'Credential';
  accessToken: Scalars['String'];
  client: Scalars['String'];
  expiry: Scalars['Int'];
  tokenType: Scalars['String'];
  uid: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  platform: Scalars['String'];
  token: Scalars['String'];
};

/** Represents direct upload credentials */
export type DirectUpload = {
  __typename?: 'DirectUpload';
  /** Created blob record ID */
  blobId: Scalars['ID'];
  /** JSON-encoded HTTP request headers */
  headers: Scalars['String'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['ID'];
  /** Upload URL */
  url: Scalars['String'];
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  source: Scalars['String'];
  type: ImageTypeEnum;
};

/** Autogenerated input type of ImageDelete */
export type ImageDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  imageId: Scalars['ID'];
};

/** Autogenerated return type of ImageDelete. */
export type ImageDeletePayload = {
  __typename?: 'ImageDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  imageId?: Maybe<Scalars['ID']>;
  success: Scalars['Boolean'];
};

export enum ImageTypeEnum {
  ClientImage = 'CLIENT_IMAGE',
  Polaroid = 'POLAROID',
  Portfolio = 'PORTFOLIO',
  ProfilePhoto = 'PROFILE_PHOTO'
}

export type Message = {
  __typename?: 'Message';
  author: User;
  booking: Booking;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
};

/** The connection type for Message. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  /** A list of nodes. */
  nodes: Array<Message>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Message>;
};

export type Meta = {
  __typename?: 'Meta';
  currentAppVersion: Scalars['String'];
  privacyLink: Scalars['String'];
  stripePublishableKey: Scalars['String'];
  tosLink: Scalars['String'];
};

export type ModelProfile = {
  __typename?: 'ModelProfile';
  bio?: Maybe<Scalars['String']>;
  bust?: Maybe<Scalars['Int']>;
  categories: Array<Scalars['String']>;
  cupSize?: Maybe<Scalars['String']>;
  dailyPay?: Maybe<Scalars['Int']>;
  dressSize?: Maybe<Scalars['String']>;
  ethnicity?: Maybe<Scalars['String']>;
  eyeColor?: Maybe<Scalars['String']>;
  followersCount?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  hairColor?: Maybe<Scalars['String']>;
  hairLength?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  hips?: Maybe<Scalars['Int']>;
  hourlyPay?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  images: Array<Image>;
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  profilePhoto?: Maybe<Scalars['String']>;
  sex?: Maybe<SexEnum>;
  shoeSize?: Maybe<Scalars['Int']>;
  user: User;
  waist?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

/** The connection type for ModelProfile. */
export type ModelProfileConnection = {
  __typename?: 'ModelProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ModelProfileEdge>>>;
  /** A list of nodes. */
  nodes: Array<ModelProfile>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ModelProfileEdge = {
  __typename?: 'ModelProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ModelProfile>;
};

export type ModelProfilesPublicStatistics = {
  __typename?: 'ModelProfilesPublicStatistics';
  histogram: Array<Scalars['Int']>;
  label: Scalars['String'];
  recordsCount: Scalars['Int'];
  recordsMax: Scalars['Float'];
  recordsMin: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Update the current user's profile photo
   * (by attaching a blob via signed ID)
   */
  attachImageToProfile?: Maybe<AttachToProfilePayload>;
  bookingAccept?: Maybe<BookingAcceptPayload>;
  bookingCreate?: Maybe<BookingCreatePayload>;
  bookingPay?: Maybe<BookingPayPayload>;
  clientProfileUpdate?: Maybe<ClientProfileUpdatePayload>;
  /**
   * Update the current user's profile photo
   * (by attaching a blob via signed ID)
   */
  imageAttachToProfile?: Maybe<AttachToProfilePayload>;
  imageDelete?: Maybe<ImageDeletePayload>;
  imagesDirectUploadCreate?: Maybe<CreateDirectUploadPayload>;
  messagesSend?: Maybe<SendMessagesPayload>;
  modelProfileUpdate?: Maybe<UpdateModelProfilePayload>;
  offerCreate?: Maybe<OfferCreatePayload>;
  paymentCreateStripeOnboardingLink?: Maybe<PaymentCreateStripeOnboardingLinkPayload>;
  pushNotificationsDeviceRegister?: Maybe<RegisterPushNotificationsDevicePayload>;
  userConfirmRegistrationWithToken?: Maybe<UserConfirmRegistrationWithTokenPayload>;
  userDelete?: Maybe<UserDeletePayload>;
  userFinishOnboarding?: Maybe<UserFinishOnboardingPayload>;
  userLogin?: Maybe<UserLoginPayload>;
  userLogout?: Maybe<UserLogoutPayload>;
  userRegister?: Maybe<RegisterPayload>;
  /**
   * If a user with the email address provided exists and is not yet confirmed
   * sends an email to that address with the confirmation link.Returns success:
   * true regardles of whether the user with such email address exists or not.
   */
  userResendConfirmationWithToken?: Maybe<ResendConfirmationWithTokenPayload>;
  userSendPasswordResetWithToken?: Maybe<SendPasswordResetWithTokenPayload>;
  userUpdate?: Maybe<UserUpdatePayload>;
  userUpdateEmail?: Maybe<UserUpdateEmailPayload>;
  userUpdatePasswordWithToken?: Maybe<UserUpdatePasswordWithTokenPayload>;
};


export type MutationAttachImageToProfileArgs = {
  input: AttachToProfileInput;
};


export type MutationBookingAcceptArgs = {
  input: BookingAcceptInput;
};


export type MutationBookingCreateArgs = {
  input: BookingCreateInput;
};


export type MutationBookingPayArgs = {
  input: BookingPayInput;
};


export type MutationClientProfileUpdateArgs = {
  input: ClientProfileUpdateInput;
};


export type MutationImageAttachToProfileArgs = {
  input: AttachToProfileInput;
};


export type MutationImageDeleteArgs = {
  input: ImageDeleteInput;
};


export type MutationImagesDirectUploadCreateArgs = {
  input: CreateDirectUploadInput;
};


export type MutationMessagesSendArgs = {
  input: SendMessagesInput;
};


export type MutationModelProfileUpdateArgs = {
  input: UpdateModelProfileInput;
};


export type MutationOfferCreateArgs = {
  input: OfferCreateInput;
};


export type MutationPaymentCreateStripeOnboardingLinkArgs = {
  input: PaymentCreateStripeOnboardingLinkInput;
};


export type MutationPushNotificationsDeviceRegisterArgs = {
  input: RegisterPushNotificationsDeviceInput;
};


export type MutationUserConfirmRegistrationWithTokenArgs = {
  confirmationToken: Scalars['String'];
};


export type MutationUserDeleteArgs = {
  input: UserDeleteInput;
};


export type MutationUserFinishOnboardingArgs = {
  input: UserFinishOnboardingInput;
};


export type MutationUserLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUserRegisterArgs = {
  accountType: AccountTypeEnum;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationUserResendConfirmationWithTokenArgs = {
  email: Scalars['String'];
};


export type MutationUserSendPasswordResetWithTokenArgs = {
  email: Scalars['String'];
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationUserUpdateEmailArgs = {
  input: UserUpdateEmailInput;
};


export type MutationUserUpdatePasswordWithTokenArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

/** Range of numbers */
export type NumberRange = {
  /** End of the range */
  end?: InputMaybe<Scalars['Int']>;
  /** Start of the range */
  start?: InputMaybe<Scalars['Int']>;
};

export type NumericFieldMeta = {
  __typename?: 'NumericFieldMeta';
  maximumValue?: Maybe<Scalars['Int']>;
  minimumValue?: Maybe<Scalars['Int']>;
};

export type Offer = {
  __typename?: 'Offer';
  clientProfile: ClientProfile;
  clientProfileId: Scalars['ID'];
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  date?: Maybe<Scalars['ISO8601Date']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  endHour?: Maybe<Scalars['ISO8601DateTime']>;
  hours?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  rights?: Maybe<Scalars['String']>;
  startHour?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  wage?: Maybe<Scalars['Int']>;
};

/** The connection type for Offer. */
export type OfferConnection = {
  __typename?: 'OfferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OfferEdge>>>;
  /** A list of nodes. */
  nodes: Array<Offer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated input type of OfferCreate */
export type OfferCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  description?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  endHour?: InputMaybe<Scalars['ISO8601DateTime']>;
  hours?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  projectName?: InputMaybe<Scalars['String']>;
  rate?: InputMaybe<Scalars['Int']>;
  rights?: InputMaybe<Scalars['String']>;
  startHour?: InputMaybe<Scalars['ISO8601DateTime']>;
  wage: Scalars['Int'];
};

/** Autogenerated return type of OfferCreate. */
export type OfferCreatePayload = {
  __typename?: 'OfferCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

/** An edge in a connection. */
export type OfferEdge = {
  __typename?: 'OfferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Offer>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of PaymentCreateStripeOnboardingLink */
export type PaymentCreateStripeOnboardingLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of PaymentCreateStripeOnboardingLink. */
export type PaymentCreateStripeOnboardingLinkPayload = {
  __typename?: 'PaymentCreateStripeOnboardingLinkPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  url: Scalars['String'];
};

export type Profile = ClientProfile | ModelProfile;

/** The connection type for Profile. */
export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Profile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Profile>;
};

export type Query = {
  __typename?: 'Query';
  bookings: BookingConnection;
  /** Find a client by id */
  client: ClientProfile;
  /** Find clients */
  clients: ClientProfileConnection;
  me?: Maybe<User>;
  messages: MessageConnection;
  meta: Meta;
  /** Find a model by id */
  model: ModelProfile;
  /** Find models */
  models: ModelProfileConnection;
  modelsCount: Array<ModelProfilesPublicStatistics>;
  /** Find an offer by id */
  offer: Offer;
  /** Find offers */
  offers: OfferConnection;
  /** Find a profile by id */
  profile: Profile;
  /** Find profiles */
  profiles: ProfileConnection;
  searchFilters: SearchFilters;
};


export type QueryBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryClientArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryClientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids: Array<Scalars['ID']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookingId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryModelArgs = {
  profileIds?: InputMaybe<Array<Scalars['ID']>>;
  searchFilters?: InputMaybe<SearchFiltersInput>;
};


export type QueryModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  profileIds?: InputMaybe<Array<Scalars['ID']>>;
  searchFilters?: InputMaybe<SearchFiltersInput>;
};


export type QueryModelsCountArgs = {
  searchFilters?: InputMaybe<SearchFiltersInput>;
};


export type QueryOfferArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryProfileArgs = {
  profileIds?: InputMaybe<Array<Scalars['ID']>>;
  searchFilters?: InputMaybe<SearchFiltersInput>;
};


export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  profileIds?: InputMaybe<Array<Scalars['ID']>>;
  searchFilters?: InputMaybe<SearchFiltersInput>;
};

/** Autogenerated return type of Register. */
export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  authenticatable?: Maybe<User>;
  /**
   * Authentication credentials. Null if resource is not active for authentication
   * (e.g. Email confirmation required) after mutation.
   */
  credentials?: Maybe<Credential>;
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of RegisterPushNotificationsDevice */
export type RegisterPushNotificationsDeviceInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  platform: Scalars['String'];
  /** An Expo push token */
  token: Scalars['String'];
};

/** Autogenerated return type of RegisterPushNotificationsDevice. */
export type RegisterPushNotificationsDevicePayload = {
  __typename?: 'RegisterPushNotificationsDevicePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  device?: Maybe<Device>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
};

/** Autogenerated return type of ResendConfirmationWithToken. */
export type ResendConfirmationWithTokenPayload = {
  __typename?: 'ResendConfirmationWithTokenPayload';
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
};

export type SearchFilters = {
  __typename?: 'SearchFilters';
  bust: NumericFieldMeta;
  categories: Array<Category>;
  eyeColor: Array<StringFieldMeta>;
  hairColor: Array<StringFieldMeta>;
  hairLength: Array<StringFieldMeta>;
  height: NumericFieldMeta;
  hips: NumericFieldMeta;
  shoeSize: NumericFieldMeta;
  waist: NumericFieldMeta;
  weight: NumericFieldMeta;
};

export type SearchFiltersInput = {
  bust?: InputMaybe<NumberRange>;
  cupSize?: InputMaybe<Array<Scalars['String']>>;
  dressSize?: InputMaybe<Array<Scalars['String']>>;
  ethnicity?: InputMaybe<Array<Scalars['String']>>;
  eyeColor?: InputMaybe<Array<Scalars['String']>>;
  hairColor?: InputMaybe<Array<Scalars['String']>>;
  height?: InputMaybe<NumberRange>;
  hips?: InputMaybe<NumberRange>;
  shoeSize?: InputMaybe<NumberRange>;
  waist?: InputMaybe<NumberRange>;
  weight?: InputMaybe<NumberRange>;
};

/** Autogenerated input type of SendMessages */
export type SendMessagesInput = {
  bookingId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  messages: Array<Scalars['String']>;
};

/** Autogenerated return type of SendMessages. */
export type SendMessagesPayload = {
  __typename?: 'SendMessagesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  messages?: Maybe<Array<Message>>;
};

/** Autogenerated return type of SendPasswordResetWithToken. */
export type SendPasswordResetWithTokenPayload = {
  __typename?: 'SendPasswordResetWithTokenPayload';
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
};

export enum SexEnum {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type StringFieldMeta = {
  __typename?: 'StringFieldMeta';
  label: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated input type of UpdateModelProfile */
export type UpdateModelProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  bust?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<Array<Scalars['String']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  cupSize?: InputMaybe<Scalars['String']>;
  dailyPay?: InputMaybe<Scalars['Int']>;
  dressSize?: InputMaybe<Scalars['String']>;
  ethnicity?: InputMaybe<Scalars['String']>;
  eyeColor?: InputMaybe<Scalars['String']>;
  hairColor?: InputMaybe<Scalars['String']>;
  hairLength?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  hips?: InputMaybe<Scalars['Int']>;
  hourlyPay?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<SexEnum>;
  shoeSize?: InputMaybe<Scalars['Int']>;
  waist?: InputMaybe<Scalars['Int']>;
  weight?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateModelProfile. */
export type UpdateModelProfilePayload = {
  __typename?: 'UpdateModelProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  modelProfile: ModelProfile;
};

export type User = {
  __typename?: 'User';
  canCreateBookings: Scalars['Boolean'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  onboarded: Scalars['Boolean'];
  profile?: Maybe<Profile>;
};

/** Autogenerated return type of UserConfirmRegistrationWithToken. */
export type UserConfirmRegistrationWithTokenPayload = {
  __typename?: 'UserConfirmRegistrationWithTokenPayload';
  authenticatable: User;
  /** Authentication credentials. Null unless user is signed in after confirmation. */
  credentials?: Maybe<Credential>;
};

/** Autogenerated input type of UserDelete */
export type UserDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

/** Autogenerated return type of UserDelete. */
export type UserDeletePayload = {
  __typename?: 'UserDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
};

export type UserError = {
  __typename?: 'UserError';
  /** The error message. */
  message: Scalars['String'];
  /** The path to the input field that caused the error. */
  path: Array<Scalars['String']>;
};

/** Autogenerated input type of UserFinishOnboarding */
export type UserFinishOnboardingInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UserFinishOnboarding. */
export type UserFinishOnboardingPayload = {
  __typename?: 'UserFinishOnboardingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  success: Scalars['Boolean'];
  user: User;
};

/** Autogenerated return type of UserLogin. */
export type UserLoginPayload = {
  __typename?: 'UserLoginPayload';
  authenticatable: User;
  credentials: Credential;
};

/** Autogenerated return type of UserLogout. */
export type UserLogoutPayload = {
  __typename?: 'UserLogoutPayload';
  authenticatable: User;
};

/** Autogenerated input type of UserUpdateEmail */
export type UserUpdateEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** Autogenerated return type of UserUpdateEmail. */
export type UserUpdateEmailPayload = {
  __typename?: 'UserUpdateEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  user: User;
};

/** Autogenerated input type of UserUpdate */
export type UserUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UserUpdatePasswordWithToken. */
export type UserUpdatePasswordWithTokenPayload = {
  __typename?: 'UserUpdatePasswordWithTokenPayload';
  authenticatable: User;
  /** Authentication credentials. Resource must be signed_in for credentials to be returned. */
  credentials?: Maybe<Credential>;
};

/** Autogenerated return type of UserUpdate. */
export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reasons why the mutation failed */
  errors?: Maybe<Array<UserError>>;
  user: User;
};

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>
    & { profile?: Maybe<(
      { __typename: 'ClientProfile' }
      & Pick<ClientProfile, 'id' | 'profilePhoto'>
    ) | (
      { __typename: 'ModelProfile' }
      & Pick<ModelProfile, 'id' | 'name' | 'bio' | 'followersCount' | 'profilePhoto' | 'bust' | 'cupSize' | 'dressSize' | 'ethnicity' | 'eyeColor' | 'gender' | 'hairColor' | 'height' | 'hips' | 'location' | 'shoeSize' | 'waist' | 'weight'>
    )> }
  )> }
);

export type FetchModelProfilesQueryVariables = Exact<{
  searchFilters?: InputMaybe<SearchFiltersInput>;
}>;


export type FetchModelProfilesQuery = (
  { __typename?: 'Query' }
  & { models: (
    { __typename?: 'ModelProfileConnection' }
    & { nodes: Array<(
      { __typename?: 'ModelProfile' }
      & Pick<ModelProfile, 'id' | 'name' | 'profilePhoto'>
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { userLogin?: Maybe<(
    { __typename?: 'UserLoginPayload' }
    & { authenticatable: (
      { __typename?: 'User' }
      & Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>
    ), credentials: (
      { __typename?: 'Credential' }
      & Pick<Credential, 'accessToken' | 'client' | 'expiry' | 'tokenType' | 'uid'>
    ) }
  )> }
);

export type ConversationBookingFragment = (
  { __typename?: 'Booking' }
  & Pick<Booking, 'id' | 'projectName' | 'clientProfileId' | 'modelProfileId' | 'updatedAt'>
  & { modelProfile: (
    { __typename?: 'ModelProfile' }
    & Pick<ModelProfile, 'profilePhoto' | 'name'>
  ) }
);

export type FetchBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchBookingsQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingConnection' }
    & { nodes: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'projectName' | 'clientProfileId' | 'modelProfileId' | 'updatedAt'>
      & { modelProfile: (
        { __typename?: 'ModelProfile' }
        & Pick<ModelProfile, 'profilePhoto' | 'name'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type FetchMessagesQueryVariables = Exact<{
  bookingId: Scalars['ID'];
  after?: InputMaybe<Scalars['String']>;
}>;


export type FetchMessagesQuery = (
  { __typename?: 'Query' }
  & { messages: (
    { __typename?: 'MessageConnection' }
    & { nodes: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'text' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type SendMessagesMutationVariables = Exact<{
  messages: Array<Scalars['String']> | Scalars['String'];
  bookingId: Scalars['ID'];
}>;


export type SendMessagesMutation = (
  { __typename?: 'Mutation' }
  & { messagesSend?: Maybe<(
    { __typename: 'SendMessagesPayload' }
    & { messages?: Maybe<Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'text' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ) }
    )>> }
  )> }
);

export type UpdateModelProfileMutationVariables = Exact<{
  cupSize: Scalars['String'];
  dressSize: Scalars['String'];
  eyeColor: Scalars['String'];
  hairColor: Scalars['String'];
  height: Scalars['Int'];
  hips: Scalars['Int'];
  shoeSize: Scalars['Int'];
  waist: Scalars['Int'];
  weight: Scalars['Int'];
}>;


export type UpdateModelProfileMutation = (
  { __typename?: 'Mutation' }
  & { modelProfileUpdate?: Maybe<(
    { __typename?: 'UpdateModelProfilePayload' }
    & { modelProfile: (
      { __typename?: 'ModelProfile' }
      & Pick<ModelProfile, 'bust' | 'cupSize' | 'dressSize' | 'ethnicity' | 'eyeColor' | 'gender' | 'hairColor' | 'height' | 'hips' | 'location' | 'shoeSize' | 'waist' | 'weight'>
    ), errors?: Maybe<Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )>> }
  )> }
);

export type FetchProfileQueryVariables = Exact<{
  profileId: Scalars['ID'];
}>;


export type FetchProfileQuery = (
  { __typename?: 'Query' }
  & { profile: (
    { __typename: 'ClientProfile' }
    & Pick<ClientProfile, 'id' | 'name' | 'profilePhoto'>
    & { images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'source' | 'type'>
    )> }
  ) | (
    { __typename: 'ModelProfile' }
    & Pick<ModelProfile, 'id' | 'name' | 'bio' | 'followersCount' | 'profilePhoto' | 'bust' | 'cupSize' | 'dressSize' | 'ethnicity' | 'eyeColor' | 'gender' | 'hairColor' | 'height' | 'hips' | 'location' | 'shoeSize' | 'waist' | 'weight'>
    & { images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'source' | 'type'>
    )> }
  ) }
);

export type CreateBookingMutationVariables = Exact<{
  profileId: Scalars['ID'];
  bookingType: BookingTypeEnum;
  details?: InputMaybe<Scalars['String']>;
  projectName?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  time?: InputMaybe<Scalars['Int']>;
  hourStart?: InputMaybe<Scalars['ISO8601DateTime']>;
  hourEnd?: InputMaybe<Scalars['ISO8601DateTime']>;
  location?: InputMaybe<Scalars['String']>;
  wage: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  rights?: InputMaybe<Scalars['String']>;
  contraindications?: InputMaybe<Scalars['String']>;
}>;


export type CreateBookingMutation = (
  { __typename?: 'Mutation' }
  & { bookingCreate?: Maybe<{ __typename: 'BookingCreatePayload' }> }
);

export type FetchOfferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchOfferQuery = (
  { __typename?: 'Query' }
  & { offer: (
    { __typename?: 'Offer' }
    & Pick<Offer, 'id' | 'name' | 'companyName' | 'location' | 'startHour' | 'endHour' | 'hours' | 'rate' | 'description' | 'details' | 'rights' | 'wage'>
  ) }
);

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  accountType: AccountTypeEnum;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & { userRegister?: Maybe<(
    { __typename?: 'RegisterPayload' }
    & { authenticatable?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>
    )>, credentials?: Maybe<(
      { __typename?: 'Credential' }
      & Pick<Credential, 'accessToken' | 'client' | 'expiry' | 'tokenType' | 'uid'>
    )>, errors?: Maybe<Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )>> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate?: Maybe<(
    { __typename?: 'UserUpdatePayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName'>
    ), errors?: Maybe<Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )>> }
  )> }
);

export const ConversationBookingFragmentDoc = gql`
    fragment ConversationBooking on Booking {
  id
  projectName
  clientProfileId
  modelProfileId
  modelProfile {
    profilePhoto
    name
  }
  updatedAt
}
    `;
export const MeDocument = gql`
    query Me {
  me {
    id
    email
    firstName
    lastName
    profile {
      __typename
      ... on ClientProfile {
        id
        profilePhoto
      }
      ... on ModelProfile {
        id
        name
        bio
        followersCount
        profilePhoto
        bust
        cupSize
        dressSize
        ethnicity
        eyeColor
        gender
        hairColor
        height
        hips
        location
        shoeSize
        waist
        weight
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const FetchModelProfilesDocument = gql`
    query FetchModelProfiles($searchFilters: SearchFiltersInput) {
  models(searchFilters: $searchFilters) {
    nodes {
      id
      name
      profilePhoto
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useFetchModelProfilesQuery__
 *
 * To run a query within a React component, call `useFetchModelProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchModelProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchModelProfilesQuery({
 *   variables: {
 *      searchFilters: // value for 'searchFilters'
 *   },
 * });
 */
export function useFetchModelProfilesQuery(baseOptions?: Apollo.QueryHookOptions<FetchModelProfilesQuery, FetchModelProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchModelProfilesQuery, FetchModelProfilesQueryVariables>(FetchModelProfilesDocument, options);
      }
export function useFetchModelProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchModelProfilesQuery, FetchModelProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchModelProfilesQuery, FetchModelProfilesQueryVariables>(FetchModelProfilesDocument, options);
        }
export type FetchModelProfilesQueryHookResult = ReturnType<typeof useFetchModelProfilesQuery>;
export type FetchModelProfilesLazyQueryHookResult = ReturnType<typeof useFetchModelProfilesLazyQuery>;
export type FetchModelProfilesQueryResult = Apollo.QueryResult<FetchModelProfilesQuery, FetchModelProfilesQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  userLogin(email: $email, password: $password) {
    authenticatable {
      email
      firstName
      id
      lastName
    }
    credentials {
      accessToken
      client
      expiry
      tokenType
      uid
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const FetchBookingsDocument = gql`
    query FetchBookings {
  bookings {
    nodes {
      ...ConversationBooking
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ConversationBookingFragmentDoc}`;

/**
 * __useFetchBookingsQuery__
 *
 * To run a query within a React component, call `useFetchBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBookingsQuery(baseOptions?: Apollo.QueryHookOptions<FetchBookingsQuery, FetchBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchBookingsQuery, FetchBookingsQueryVariables>(FetchBookingsDocument, options);
      }
export function useFetchBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchBookingsQuery, FetchBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchBookingsQuery, FetchBookingsQueryVariables>(FetchBookingsDocument, options);
        }
export type FetchBookingsQueryHookResult = ReturnType<typeof useFetchBookingsQuery>;
export type FetchBookingsLazyQueryHookResult = ReturnType<typeof useFetchBookingsLazyQuery>;
export type FetchBookingsQueryResult = Apollo.QueryResult<FetchBookingsQuery, FetchBookingsQueryVariables>;
export const FetchMessagesDocument = gql`
    query FetchMessages($bookingId: ID!, $after: String) {
  messages(bookingId: $bookingId, after: $after) {
    nodes {
      id
      text
      createdAt
      author {
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useFetchMessagesQuery__
 *
 * To run a query within a React component, call `useFetchMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessagesQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchMessagesQuery(baseOptions: Apollo.QueryHookOptions<FetchMessagesQuery, FetchMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchMessagesQuery, FetchMessagesQueryVariables>(FetchMessagesDocument, options);
      }
export function useFetchMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMessagesQuery, FetchMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchMessagesQuery, FetchMessagesQueryVariables>(FetchMessagesDocument, options);
        }
export type FetchMessagesQueryHookResult = ReturnType<typeof useFetchMessagesQuery>;
export type FetchMessagesLazyQueryHookResult = ReturnType<typeof useFetchMessagesLazyQuery>;
export type FetchMessagesQueryResult = Apollo.QueryResult<FetchMessagesQuery, FetchMessagesQueryVariables>;
export const SendMessagesDocument = gql`
    mutation SendMessages($messages: [String!]!, $bookingId: ID!) {
  messagesSend(input: {messages: $messages, bookingId: $bookingId}) {
    __typename
    messages {
      id
      text
      createdAt
      author {
        id
      }
    }
  }
}
    `;
export type SendMessagesMutationFn = Apollo.MutationFunction<SendMessagesMutation, SendMessagesMutationVariables>;

/**
 * __useSendMessagesMutation__
 *
 * To run a mutation, you first call `useSendMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessagesMutation, { data, loading, error }] = useSendMessagesMutation({
 *   variables: {
 *      messages: // value for 'messages'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useSendMessagesMutation(baseOptions?: Apollo.MutationHookOptions<SendMessagesMutation, SendMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessagesMutation, SendMessagesMutationVariables>(SendMessagesDocument, options);
      }
export type SendMessagesMutationHookResult = ReturnType<typeof useSendMessagesMutation>;
export type SendMessagesMutationResult = Apollo.MutationResult<SendMessagesMutation>;
export type SendMessagesMutationOptions = Apollo.BaseMutationOptions<SendMessagesMutation, SendMessagesMutationVariables>;
export const UpdateModelProfileDocument = gql`
    mutation UpdateModelProfile($cupSize: String!, $dressSize: String!, $eyeColor: String!, $hairColor: String!, $height: Int!, $hips: Int!, $shoeSize: Int!, $waist: Int!, $weight: Int!) {
  modelProfileUpdate(
    input: {cupSize: $cupSize, dressSize: $dressSize, eyeColor: $eyeColor, hairColor: $hairColor, height: $height, hips: $hips, shoeSize: $shoeSize, waist: $waist, weight: $weight}
  ) {
    modelProfile {
      bust
      cupSize
      dressSize
      ethnicity
      eyeColor
      gender
      hairColor
      height
      hips
      location
      shoeSize
      waist
      weight
    }
    errors {
      message
      path
    }
  }
}
    `;
export type UpdateModelProfileMutationFn = Apollo.MutationFunction<UpdateModelProfileMutation, UpdateModelProfileMutationVariables>;

/**
 * __useUpdateModelProfileMutation__
 *
 * To run a mutation, you first call `useUpdateModelProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelProfileMutation, { data, loading, error }] = useUpdateModelProfileMutation({
 *   variables: {
 *      cupSize: // value for 'cupSize'
 *      dressSize: // value for 'dressSize'
 *      eyeColor: // value for 'eyeColor'
 *      hairColor: // value for 'hairColor'
 *      height: // value for 'height'
 *      hips: // value for 'hips'
 *      shoeSize: // value for 'shoeSize'
 *      waist: // value for 'waist'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useUpdateModelProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelProfileMutation, UpdateModelProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelProfileMutation, UpdateModelProfileMutationVariables>(UpdateModelProfileDocument, options);
      }
export type UpdateModelProfileMutationHookResult = ReturnType<typeof useUpdateModelProfileMutation>;
export type UpdateModelProfileMutationResult = Apollo.MutationResult<UpdateModelProfileMutation>;
export type UpdateModelProfileMutationOptions = Apollo.BaseMutationOptions<UpdateModelProfileMutation, UpdateModelProfileMutationVariables>;
export const FetchProfileDocument = gql`
    query FetchProfile($profileId: ID!) {
  profile(profileIds: [$profileId]) {
    __typename
    ... on ClientProfile {
      id
      name
      profilePhoto
      images {
        id
        source
        type
      }
    }
    ... on ModelProfile {
      id
      name
      bio
      followersCount
      profilePhoto
      images {
        id
        source
        type
      }
      bust
      cupSize
      dressSize
      ethnicity
      eyeColor
      gender
      hairColor
      height
      hips
      location
      shoeSize
      waist
      weight
    }
  }
}
    `;

/**
 * __useFetchProfileQuery__
 *
 * To run a query within a React component, call `useFetchProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfileQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useFetchProfileQuery(baseOptions: Apollo.QueryHookOptions<FetchProfileQuery, FetchProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchProfileQuery, FetchProfileQueryVariables>(FetchProfileDocument, options);
      }
export function useFetchProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchProfileQuery, FetchProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchProfileQuery, FetchProfileQueryVariables>(FetchProfileDocument, options);
        }
export type FetchProfileQueryHookResult = ReturnType<typeof useFetchProfileQuery>;
export type FetchProfileLazyQueryHookResult = ReturnType<typeof useFetchProfileLazyQuery>;
export type FetchProfileQueryResult = Apollo.QueryResult<FetchProfileQuery, FetchProfileQueryVariables>;
export const CreateBookingDocument = gql`
    mutation CreateBooking($profileId: ID!, $bookingType: BookingTypeEnum!, $details: String, $projectName: String, $company: String, $date: ISO8601Date!, $time: Int, $hourStart: ISO8601DateTime, $hourEnd: ISO8601DateTime, $location: String, $wage: Int!, $description: String, $rights: String, $contraindications: String) {
  bookingCreate(
    input: {profileId: $profileId, bookingType: $bookingType, details: $details, projectName: $projectName, companyName: $company, date: $date, hours: $time, startHour: $hourStart, endHour: $hourEnd, location: $location, wage: $wage, description: $description, rights: $rights, contraindications: $contraindications}
  ) {
    __typename
  }
}
    `;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      bookingType: // value for 'bookingType'
 *      details: // value for 'details'
 *      projectName: // value for 'projectName'
 *      company: // value for 'company'
 *      date: // value for 'date'
 *      time: // value for 'time'
 *      hourStart: // value for 'hourStart'
 *      hourEnd: // value for 'hourEnd'
 *      location: // value for 'location'
 *      wage: // value for 'wage'
 *      description: // value for 'description'
 *      rights: // value for 'rights'
 *      contraindications: // value for 'contraindications'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const FetchOfferDocument = gql`
    query FetchOffer($id: ID!) {
  offer(ids: [$id]) {
    id
    name
    companyName
    location
    startHour
    endHour
    hours
    rate
    description
    details
    rights
    wage
  }
}
    `;

/**
 * __useFetchOfferQuery__
 *
 * To run a query within a React component, call `useFetchOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchOfferQuery(baseOptions: Apollo.QueryHookOptions<FetchOfferQuery, FetchOfferQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchOfferQuery, FetchOfferQueryVariables>(FetchOfferDocument, options);
      }
export function useFetchOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchOfferQuery, FetchOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchOfferQuery, FetchOfferQueryVariables>(FetchOfferDocument, options);
        }
export type FetchOfferQueryHookResult = ReturnType<typeof useFetchOfferQuery>;
export type FetchOfferLazyQueryHookResult = ReturnType<typeof useFetchOfferLazyQuery>;
export type FetchOfferQueryResult = Apollo.QueryResult<FetchOfferQuery, FetchOfferQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($email: String!, $password: String!, $passwordConfirmation: String!, $firstName: String!, $lastName: String!, $phoneNumber: String!, $accountType: AccountTypeEnum!) {
  userRegister(
    email: $email
    password: $password
    passwordConfirmation: $passwordConfirmation
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
    accountType: $accountType
  ) {
    authenticatable {
      email
      firstName
      id
      lastName
    }
    credentials {
      accessToken
      client
      expiry
      tokenType
      uid
    }
    errors {
      message
      path
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($firstName: String, $lastName: String) {
  userUpdate(input: {firstName: $firstName, lastName: $lastName}) {
    user {
      firstName
      lastName
    }
    errors {
      message
      path
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Profile": [
      "ClientProfile",
      "ModelProfile"
    ]
  }
};
      export default result;
    