import { useIntl } from 'react-intl';

import styles from './OfferDetails.module.scss';
import { OfferType } from '../types';

type Props = {
  offer: OfferType
}

export const OfferDetails = ({ offer }: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <>
      <div className={styles.detailsContainer}>
        <div className={styles.detailColumn}>
          <p className={styles.detailLabel}>
            { f({ id: 'offers.owner' }) }
            :

          </p>
          <p className={styles.detailText}>{ offer.companyName }</p>
        </div>

        <div className={styles.detailRow}>
          <p className={styles.detailLabel}>
            { f({ id: 'offers.date' }) }
            :
          </p>
          <p className={styles.detailText}>{ offer.date }</p>
        </div>

        <div className={styles.detailRow}>
          <p className={styles.detailLabel}>
            { f({ id: 'offers.start_time' }) }
            :

          </p>
          <p className={styles.detailText}>{ offer.startHour }</p>
        </div>

        <div className={styles.detailRow}>
          <p className={styles.detailLabel}>
            { f({ id: 'offers.end_time' }) }
            :

          </p>
          <p className={styles.detailText}>{ offer.endHour }</p>
        </div>

        <div className={styles.detailRow}>
          <p className={styles.detailLabel}>
            { f({ id: 'offers.hourly_rate' }) }
            :

          </p>
          <p className={styles.detailText}>
            { offer.rate }
            zł
          </p>
        </div>

        <div className={styles.detailColumn}>
          <p className={styles.detailLabel}>
            { f({ id: 'offers.address' }) }
            :
          </p>
          <p className={styles.detailText}>
            { offer.location }
            { /* { offer.city }
                ,
                { ' ' }
                { offer.address } */ }
          </p>
        </div>

      </div>
      <div className={`${styles.detailColumn} ${styles.lastDetail}`}>
        <p className={styles.detailLabel}>
          { f({ id: 'offers.additional_info' }) }
          :
        </p>
        <p className={styles.detailText}>{ offer.details }</p>
      </div>
    </>
  );
};
