import * as React from 'react';

import { Formik, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import styles from './Register.module.scss';
import { RegisterForm } from './components';
import { TextWithBg, TitleText, LoadingScreen } from '../../components/UI';
import { RegisterFormTypes, useRegisterSchema } from '../../core/schemas';
import { useRegisterMutation } from '../../generated/graphql';
import { useApplicationInternals } from '../../core/hooks';

export const Register = () => {
  const authenticate = useApplicationInternals((state) => state.authenticate);
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const { initialValues, schema } = useRegisterSchema();

  const formRef = React.useRef<FormikProps<RegisterFormTypes>>(null);

  const [register, { loading }] = useRegisterMutation({
    onCompleted: (data) => {
      if (data?.userRegister?.credentials) {
        authenticate(data.userRegister.credentials);
      } else {
        // TODO: Handle non input user errors (path[0] != attributes)
        // toast.error('Coś poszło nie tak');
        data?.userRegister?.errors?.forEach((userError) => {
          const { message, path } = userError;

          formRef.current?.setFieldError(path[1], message);
        });
      }
    },
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  const handleLoginNavigate = () => {
    navigate('/login');
  };

  return (
    <>
      { loading && <LoadingScreen /> }
      <div className={styles.container}>
        <div className={styles.header}>
          <TextWithBg top={-10} left={-20} containerStyle={styles.textWithBg}>
            <TitleText className={styles.headerPrimaryText}>
              { f({ id: 'register.title' }) }
            </TitleText>
          </TextWithBg>
          <p className={styles.headerSecondaryText}>
            { f({ id: 'register.subtitle' }) }
          </p>
        </div>
        <Formik
          component={RegisterForm}
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(data) => register({ variables: data })}
        />
        <button type="button" className={styles.loginButton} onClick={handleLoginNavigate}>
          { f({ id: 'register.loginInstead' }) }
        </button>
      </div>
    </>
  );
};
