import { useIntl } from 'react-intl';
import { Formik } from 'formik';

import { toast } from 'react-toastify';
import backgroundImage from '~/images/login-background.jpg';
import { useLoginMutation } from '../../generated/graphql';
import { LoginForm } from './components';
import { TextWithBg, TitleText, LoadingScreen } from '../../components/UI';
import { useLoginSchema } from '../../core/schemas';
import styles from './Login.module.scss';
import { useApplicationInternals } from '../../core/hooks';

export const Login = () => {
  const authenticate = useApplicationInternals((state) => state.authenticate);
  const { formatMessage: f } = useIntl();
  const { initialValues, schema } = useLoginSchema();

  const [login, { loading }] = useLoginMutation({
    onCompleted: (data) => {
      if (data?.userLogin?.credentials) {
        authenticate(data.userLogin.credentials);
      } else {
        toast.error('Coś poszło nie tak');
      }
    },
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  return (
    <>
      { loading && <LoadingScreen /> }
      <div className={styles.container}>
        <img className={styles.backgroundImage} src={backgroundImage} alt="Models" />
        <div className={styles.loginCard}>
          <div className={styles.innerLoginCard}>
            <div className={styles.header}>
              <TextWithBg top={-10} left={-20} containerStyle={styles.textWithBg}>
                <TitleText className={styles.headerPrimaryText}>
                  { f({ id: 'login.title' }) }
                </TitleText>
              </TextWithBg>
              <p className={styles.headerSecondaryText}>
                { f({ id: 'login.subtitle' }) }
              </p>
            </div>
            <Formik
              component={LoginForm}
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={(credentials) => login({ variables: credentials })}
            />
          </div>
        </div>
      </div>
    </>
  );
};
