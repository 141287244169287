import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { TitleText, Button } from '../../../components/UI';
import { ShortOffer } from '../types';
import { OfferItem } from './OfferItem';
import styles from './OffersList.module.scss';

type Props = {
  offers: ShortOffer[]
  myOffers?: boolean
}

export const OffersList = ({ offers, myOffers = false }: Props) => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const emptyArray = offers.length === 0;

  const handleNavigate = (route: string) => {
    navigate(`/${route}`);
  };

  if (myOffers && emptyArray) {
    return (
      <div className={`${styles.container} ${styles.noMyOffers}`}>
        <TitleText className={styles.title}>Moje zlecenia</TitleText>
        <p className={styles.text}>
          { f({ id: 'offers.no_my_offers' }) }
        </p>
        <div className={styles.buttonsContainer}>
          <Button buttonStyle={styles.button} onClick={() => handleNavigate('new-offer')}>{ f({ id: 'offers.add_offer' }) }</Button>
          <Button notifications={5} buttonStyle={styles.button} onClick={() => handleNavigate('casting-invitation/1')}>{ f({ id: 'offers.casting' }) }</Button>
        </div>
      </div>
    );
  }

  if (emptyArray) {
    return (
      <div className={styles.noItemsContainer}>
        <p>{ f({ id: 'offers.noAllOffers' }) }</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      { offers.map((offer) => <OfferItem key={offer.id} offer={offer} />) }
    </div>
  );
};
