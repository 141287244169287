import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from '../Footer.module.scss';

type Props = {
  name: string;
};

const LinkItem = ({ name }: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <Link to={name} className={styles.linkItem}>
      { f({ id: `footer.links.${name}` }) }
    </Link>
  );
};

export default LinkItem;
