import * as Yup from 'yup';
import { isValid } from 'date-fns';

export const isDateValid = (
  value: string | undefined,
  yupCtx: Yup.TestContext,
) => {
  if (value) {
    const data = value.split('/');
    const date = new Date(`${data[2]}-${data[1]}-${data[0]}`);

    if (isValid(date)) {
      return true;
    }
    return yupCtx.createError();
  }
  return false;
};

export const isTimeValid = (
  value: string | undefined,
  yupCtx: Yup.TestContext,
) => {
  if (value) {
    const data = value.split(':');
    const hours = Number(data[0]);
    const minutes = Number(data[1]);

    const validHours = !!(!Number.isNaN(hours) && hours >= 0 && hours < 24);
    const validMinutes = !!(!Number.isNaN(minutes) && minutes >= 0 && minutes < 60);

    if (validHours && validMinutes) {
      return true;
    }
    return yupCtx.createError();
  }
  return false;
};

export const isTimeGreater = (
  value: string | undefined,
  yupCtx: Yup.TestContext,
) => {
  if (yupCtx.parent.hourStart && value) {
    const [startHours, startMinutes] = yupCtx.parent.hourStart.split(':');
    const [endHours, endMinutes] = value.split(':');

    if (parseInt(endHours, 10) > parseInt(startHours, 10)) {
      return true;
    }
    if (parseInt(endHours, 10) < parseInt(startHours, 10)) {
      return yupCtx.createError();
    }
    return parseInt(endMinutes, 10) > parseInt(startMinutes, 10);
  }
  return false;
};
