import { useField } from 'formik';
import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useOutsideDiv } from '../../../../core/hooks';
import styles from './Measurements.module.scss';

type Props = {
  name: string
  disabled?: boolean
}

const options = ['1', '2', '3', '4', '5'];

export const MeasurementSelect = ({
  name,
  disabled = false,
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const { formatMessage: f } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useOutsideDiv(() => setIsOpen(false));
  const nodeRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    setIsOpen(false);
  }, [disabled]);

  const handleDropdown = () => {
    if (!disabled) {
      setIsOpen((prevState) => !prevState);
    }
  };

  const handleSelect = (value: typeof options[number]) => {
    helpers.setValue(value);
    setIsOpen(false);
  };

  const isError = !!(meta.touched && meta.error);

  return (
    <div ref={ref} className={styles.measurementItem}>
      <span className={styles.measurementLabel}>
        { f({ id: `measurements.${name}` }) }
        :
      </span>
      <button
        type="button"
        className={`
          ${styles.measurementValue}
          ${disabled && styles.measurementValueDisabled}
          ${isError && styles.error}
       `}
        onClick={handleDropdown}
      >
        { field.value }
        { !disabled && (
          <div className={styles.dropdownIcon}>
            <BsChevronDown className={`${styles.icon} ${isOpen && styles.activeIcon}`} />
          </div>
        ) }
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={300}
        classNames="openClose"
        unmountOnExit
      >
        <div ref={nodeRef} className={styles.optionsContainer}>
          { options.map((option) => (
            <button key={option} type="button" className={`${styles.measurementValue} ${styles.selectOption}`} onClick={() => handleSelect(option)}>
              { option }
            </button>
          )) }
        </div>
      </CSSTransition>
    </div>
  );
};
