export const parseToBackendId = (profileType: 'model' | 'client', userId: string | undefined) => {
  if (!userId) {
    return '';
  }

  const result = `gid://mybooker/${profileType === 'model' ? 'ModelProfile' : 'ClientProfile'}/${userId}`;

  return result;
};

export const convertToGlobalId = (id: string | number, modelType: string) => {
  return `gid://mybooker/${modelType}/${id}`;
};

export const parseToRouteId = (userId: string | undefined) => {
  if (!userId) {
    return '';
  }

  const [result] = userId.split('/').slice(-1);

  return result;
};
