import React from 'react';
import { IntlProvider } from 'react-intl';

import { locale } from '../../locales/pl/index';

type Props = {
  children: React.ReactNode;
};

export const Intl = ({ children }: Props) => {
  return (
    <IntlProvider locale="pl" messages={locale}>
      { children }
    </IntlProvider>
  );
};
