import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BookingTypeEnum, CreateBookingMutationVariables, useCreateBookingMutation } from '../../generated/graphql';
import { LoadingScreen, TitleText } from '../../components/UI';
import { NewOfferForm } from './components';
import { useNewOfferSchema } from '../../core/schemas';
import styles from './NewOffer.module.scss';
import { useUser } from '../../core/providers/User';
import { formatDateFromSlashToDash } from '../../core/helpers';
import { parseToBackendId } from '../../core/helpers/idParsers';

export const NewOffer = () => {
  const { formatMessage: f } = useIntl();
  const { initialValues, schema } = useNewOfferSchema();
  const { user } = useUser();
  const navigate = useNavigate();
  const params = useParams();

  console.log(params);

  const [createBooking, { loading }] = useCreateBookingMutation({
    onCompleted: () => {
      navigate('/offers');
    },
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  const handleSubmit = (values: Omit<CreateBookingMutationVariables, 'profileId' | 'bookingType'>) => {
    const { date, hourStart, hourEnd } = values;

    const formattedDate = formatDateFromSlashToDash(date);
    const formattedStart = `${formattedDate}T${hourStart}:00`;
    const formattedEnd = `${formattedDate}T${hourEnd}:00`;

    const profileId = params.modelId ?
      parseToBackendId('model', params.modelId) :
      user?.profile?.id as string;
    const bookingType = params.bookingType ?
      params.bookingType as BookingTypeEnum :
      BookingTypeEnum.Casting;

    createBooking({
      variables: {
        ...values,
        profileId,
        bookingType,
        date: formattedDate,
        hourStart: formattedStart,
        hourEnd: formattedEnd,
        wage: Number(values.wage),
        time: Math.floor(Number(values.time)),
      },
    });
  };

  return (
    <>
      { loading && <LoadingScreen /> }
      <div className={styles.container}>
        <TitleText>{ f({ id: 'newOffer.header' }) }</TitleText>
        <Formik
          initialValues={initialValues}
          component={NewOfferForm}
          validationSchema={schema}
          onSubmit={(values) => {
            if (!user) {
              toast.error('Zaloguj się ponownie');
              return;
            }

            handleSubmit(values);
          }}
        />
      </div>
    </>
  );
};
