import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { RolesCheckbox } from '.';
import { TextInput, Button } from '../../../components/UI';
import styles from '../Register.module.scss';
import { RegisterFormTypes } from '../../../core/schemas';

type Props = FormikProps<RegisterFormTypes>;

export const RegisterForm = ({ handleSubmit }: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit} autoComplete="on">
      <div className={styles.rowInputs}>
        <TextInput name="firstName" form="register" />
        <TextInput name="lastName" form="register" />
      </div>
      <TextInput name="email" form="register" type="email" />
      <TextInput name="phoneNumber" form="register" type="tel" />
      <TextInput name="city" form="register" />
      <RolesCheckbox name="accountType" />
      <TextInput name="password" form="register" type="password" />
      <TextInput name="passwordConfirmation" form="register" type="password" />
      <Button type="submit" buttonStyle={styles.submitButton} accent>
        { f({ id: 'register.submitButton' }) }
      </Button>
    </form>
  );
};
