import { TitleText } from '../Text';
import styles from './ErrorScreen.module.scss';

type Props = {
  errorMessage: string
}

export const ErrorScreen = ({ errorMessage }: Props) => {
  return (
    <div className={styles.container}>
      <TitleText>Error Occured</TitleText>
      <p>{ errorMessage }</p>
    </div>
  );
};
