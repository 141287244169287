import { useEffect, useRef } from 'react';

type Props = () => void;

export const useOutsideDiv = (callback: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = ({ target }: MouseEvent) => {
      if (target && ref.current && !ref.current.contains(target as Node)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, callback]);
  return ref;
};
