import { ReactNode } from 'react';
import styles from './ErrorText.module.scss';

type Props = {
  children: ReactNode
  bottom?: number
}

export const ErrorText = ({ children, bottom }: Props) => {
  // Require position relative on parent
  return (
    <p className={styles.errorText} style={{ bottom: `${bottom}px` }}>
      { children }
    </p>
  );
};
