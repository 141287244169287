import addPayment from './addPayment.json';
import companyProfile from './companyProfile.json';
import discover from './discover.json';
import footer from './footer.json';
import home from './home.json';
import login from './login.json';
import measurements from './measurements.json';
import messenger from './messenger.json';
import modelProfile from './modelProfile.json';
import navbarLinks from './navbarLinks.json';
import newOffer from './newOffer.json';
import offers from './offers.json';
import questionType from './questionType.json';
import register from './register.json';
import settings from './settings.json';
import validationErrors from './validationErrors.json';

export const locale = {
  ...addPayment,
  ...companyProfile,
  ...discover,
  ...register,
  ...footer,
  ...home,
  ...login,
  ...measurements,
  ...messenger,
  ...modelProfile,
  ...navbarLinks,
  ...newOffer,
  ...offers,
  ...questionType,
  ...settings,
  ...validationErrors,
};
