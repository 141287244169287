import { useState } from 'react';
import { useIntl } from 'react-intl';
import styles from './Opinions.module.scss';
import { OpinionItem } from '.';

const categories = ['all', 'latest', 'oldest'] as const;

const opinions = [
  {
    author: 'Aleksandra Kielan',
    rating: 4.3,
    opinion: '"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus modi fugiat eos porro ratione, tempora distinctio autem, omnis in a eveniet unde aperiam obcaecati maiores magni, suscipit consequuntur harum ad!"',
    img: new URL('../../../assets/modelProfile/modelProfile1.jpg', import.meta.url).href,
  },
  {
    author: 'Aleksandra Kielan',
    rating: 3.8,
    opinion: '"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus modi fugiat eos porro ratione, tempora distinctio autem, omnis in a eveniet unde aperiam obcaecati maiores magni, suscipit consequuntur harum ad!"',
    img: new URL('../../../assets/modelProfile/modelProfile2.jpg', import.meta.url).href,
  },
  {
    author: 'Aleksandra Kielan',
    rating: 4.8,
    opinion: '"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus modi fugiat eos porro ratione, tempora distinctio autem, omnis in a eveniet unde aperiam obcaecati maiores magni, suscipit consequuntur harum ad!"',
    img: new URL('../../../assets/modelProfile/modelProfile3.jpg', import.meta.url).href,
  },
  {
    author: 'Aleksandra Kielan',
    rating: 4.5,
    opinion: '"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus modi fugiat eos porro ratione, tempora distinctio autem, omnis in a eveniet unde aperiam obcaecati maiores magni, suscipit consequuntur harum ad!"',
    img: new URL('../../../assets/modelProfile/modelProfile4.jpg', import.meta.url).href,
  },
];

export const Opinions = () => {
  const { formatMessage: f } = useIntl();
  const [selectedCategory, setSelectedCategory] = useState<typeof categories[number]>('all');

  const handleCategoryChange = (category: typeof categories[number]) => {
    setSelectedCategory(category);
  };

  return (
    <div className={styles.portfolioContainer}>
      <p className={styles.title}>{ f({ id: 'companyProfile.opinions' }) }</p>

      <div className={styles.categoriesContainer}>
        { categories.map((category, index) => (
          <div className={styles.opinionContainer} key={category}>
            <button
              type="button"
              className={`${styles.category} ${
                category === selectedCategory && styles.active
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              { f({ id: `companyProfile.opinions.${category}` }) }
            </button>
            { index + 1 < categories.length && <div className={styles.dot} /> }
          </div>
        )) }
      </div>

      <div className={styles.carouselContainer}>
        { opinions.map((opinion) => (
          <div className={styles.carouselItem} key={opinion.author + Date.now}>
            <OpinionItem opinion={opinion} />
          </div>
        )) }
      </div>
    </div>
  );
};
