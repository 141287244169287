import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { useIntl } from 'react-intl';
import InputMask from 'react-input-mask';
import styles from './TimePicker.module.scss';
import { ErrorText } from '../../Text';

type Props = {
  name: string
  form: string
  containerStyle?: string
}

export const TimePicker = ({ name, form, containerStyle }: Props) => {
  const { formatMessage: f } = useIntl();
  const [field, meta, helpers] = useField(name);
  const [timePickerValue, setTimePickerValue] = useState<string>('');

  const handleTimePickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    helpers.setValue(value);

    if (!value.includes('_') && !formCtx.error) {
      setTimePickerValue(value);
    }
  };

  const formCtx = {
    error: meta.error,
    touched: meta.touched,
    props: {
      value: field.value,
      onChange: handleTimePickerChange,
      onBlur: () => {
        helpers.setTouched(true);
      },
    },
  };

  const shouldShowError = formCtx.error && formCtx.touched;

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <label className={styles.label}>
        { f({ id: `${form}.${name}.label` }) }
      </label>
      <div
        className={`${styles.inputContainer} ${
          shouldShowError && styles.errorBorder
        }`}
      >
        <InputMask
          type="text"
          mask="99:99"
          className={styles.input}
          placeholder={f({ id: `${form}.${name}.placeholder` })}
          {...formCtx.props}
        />
        <span className={styles.timepickerToggle}>
          <BiTimeFive size={20} className={styles.timepickerToggleButton} />
          <input
            type="time"
            className={styles.timepickerInput}
            value={timePickerValue}
            onChange={handleTimePickerChange}
          />
        </span>
        { shouldShowError && <ErrorText bottom={-10}>{ formCtx.error }</ErrorText> }
      </div>
    </div>
  );
};
