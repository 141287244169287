import { useField } from 'formik';
import { useState } from 'react';

import { Chip } from '~/components/UI';

import styles from './FiltersAndCategories.module.scss';

// TODO(future): Add the rest of the categories
const categories = {
  highFashion: 'High Fashion',
  catwalk: 'Wybieg',
  commerce: 'Komercja',
  plusSize: 'Plus size',
  curve: 'Curve',
  sport: 'Sport',
  event: 'Event',

  /* plusSize: 'plus size',
   * bikini: 'bikini',
   * lingerie: 'bielizna',
   * commerce: 'komercja',
   * events: 'eventy',
   * influencer: 'influencer',
   * newFace: 'new face',
   * sport: 'sport',
   * catwalk: 'wybieg',
   * feet: 'stopy',
   * eCommerce: 'e-commerce',
   * hands: 'dłonie',
   * gymnastics: 'gimnastyka',
   * swimming: 'pływanie',
   * dance: 'taniec',
   * beauty: 'beauty',
   * freckles: 'piegi',
   * diastema: 'diastema',
   * boudior: 'boudior',
   * showroom: 'showroom',
   * fitModel: 'fit model',
   * lifestyle: 'lifestyle',
   * edytorial: 'edytorial', */
};

const allCategories = Object.keys(categories);

export const Categories = () => {
  const [field, , helpers] = useField('categories');

  const categoriesObject = allCategories.reduce(
    (prev, key) => ({ ...prev, [key]: field.value.includes(key) }),
    {},
  );

  const [selectedCategories, setSelectedCategories] = useState<{
    [key: string]: boolean;
  }>(categoriesObject);

  const handleOnCategoryClick = (category: keyof typeof selectedCategories) => {
    const updatedSelectedCategories = {
      ...selectedCategories,
      [category]: !selectedCategories[category],
    };
    setSelectedCategories(updatedSelectedCategories);
    const fieldCategories = Object.keys(updatedSelectedCategories).filter(
      (key) => updatedSelectedCategories[key],
    );
    helpers.setValue(fieldCategories);
  };

  return (
    <div className={styles.chipsContainer}>
      { allCategories.map((option) => (
        <Chip
          key={option}
          chipStyle={styles.chipItem}
          clicked={selectedCategories[option]}
          onClick={() => handleOnCategoryClick(option)}
        >
          { categories[option as keyof typeof categories] }
        </Chip>
      )) }
    </div>
  );
};
