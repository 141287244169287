import { useIntl } from 'react-intl';
import { ModelProfile } from '../../../../generated/graphql';
import { ModelProfileType } from '../../ModelProfile';
import styles from './Measurements.module.scss';
import { MeasurementsForm } from './MeasurementsForm';

type MeasurementsType = Pick<ModelProfile, 'height' | 'weight' | 'dressSize' | 'cupSize' | 'waist' | 'hips' | 'eyeColor' | 'hairColor' | 'shoeSize'>

type Props = {
  name: keyof MeasurementsType
  value: MeasurementsType[keyof MeasurementsType]
};

const measurementsLabels: (keyof MeasurementsType)[] = ['height', 'weight', 'dressSize', 'cupSize', 'waist', 'hips', 'eyeColor', 'hairColor', 'shoeSize'];

export const MeasurementItem = ({ name, value }: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className={styles.measurementItem}>
      <span className={styles.measurementLabel}>
        { f({ id: `measurements.${name}` }) }
        :
      </span>

      <span className={`${styles.measurementValue} ${styles.noBorder}`}>{ value }</span>
    </div>
  );
};

type MeasurementsProps = {
  data: ModelProfileType;
  myProfile: boolean;
};

export const Measurements = ({ data, myProfile }: MeasurementsProps) => {
  return (
    <div
      className={styles.container}
    >
      { myProfile ? (
        <MeasurementsForm />
      ) : (
        <div className={styles.measurementsContainer}>
          { measurementsLabels.map((label) => (
            <MeasurementItem name={label} value={data[label]} key={label} />
          )) }
        </div>
      ) }
    </div>
  );
};
