import {
  ClientSection,
  Header,
  IntroduceSection,
  ModelSection,
} from './components';
import styles from './Home.module.scss';

export const Home = () => {
  return (
    <div className={styles.container}>
      <Header />
      <IntroduceSection />
      <ModelSection />
      <ClientSection />
    </div>
  );
};
