import { Routes as RouterRoutes, Route } from 'react-router-dom';

import { ScrollToTop } from '../../components';
import { LoadingScreen } from '../../components/UI';
import {
  Login,
  Register,
  ModelProfile,
  CompanyProfile,
  Discover,
  AddPayment,
  NewOffer,
  Offers,
  Offer,
  Home,
  Messenger,
  QuestionType,
  Settings,
  CastingInvitation,
  NotFound,
} from '../../pages';
import { useUser } from '../providers/User';
import { ProtectedRoute } from './ProtectedRoute';
import { LoggedInRoute } from './LoggedInRoute';
import { MyProfileRoute } from './MyProfileRoute';

const Routes = () => {
  const { loading } = useUser();
  if (loading) {
    return (
      <main className="wrapper">
        <LoadingScreen />
      </main>
    );
  }

  return (
    <ScrollToTop>
      <main className="wrapper">
        <RouterRoutes>
          <Route path="/" element={<Home />} />
          <Route
            path="/register"
            element={(
              <LoggedInRoute>
                <Register />
              </LoggedInRoute>
            )}
          />
          <Route
            path="/login"
            element={(
              <LoggedInRoute>
                <Login />
              </LoggedInRoute>
            )}
          />

          <Route
            path="/model/:modelId"
            element={(
              <ProtectedRoute>
                <ModelProfile />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/company/:companyId"
            element={(
              <ProtectedRoute>
                <CompanyProfile />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/my-profile"
            element={(
              <ProtectedRoute>
                <MyProfileRoute />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/discover"
            element={(
              <ProtectedRoute>
                <Discover />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/add-payment"
            element={(
              <ProtectedRoute>
                <AddPayment />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/offers"
            element={(
              <ProtectedRoute>
                <Offers />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/offer/:offerId"
            element={(
              <ProtectedRoute>
                <Offer />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/new-offer"
            element={(
              <ProtectedRoute>
                <NewOffer />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/new-offer/:modelId/:bookingType"
            element={(
              <ProtectedRoute>
                <NewOffer />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/casting-invitation/:castingId"
            element={(
              <ProtectedRoute>
                <CastingInvitation />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/messenger"
            element={(
              <ProtectedRoute>
                <Messenger />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/question-type/:modelId"
            element={(
              <ProtectedRoute>
                <QuestionType />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/settings"
            element={(
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            )}
          />
          <Route path="*" element={<NotFound />} />
        </RouterRoutes>
      </main>
    </ScrollToTop>
  );
};

export default Routes;
