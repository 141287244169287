import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { create } from 'zustand';

import {
  removeCredentials,
  storeCredentials,
  createApiClient,
  LocalCredentials,
  getCredentials,
} from '../utils';

type ApplicationInternalsState = {
  credentials: LocalCredentials | undefined
  apiClient: ApolloClient<NormalizedCacheObject>

  authenticate: (credentials: LocalCredentials) => void
  logout: () => void
}

export const useApplicationInternals = create<ApplicationInternalsState>((set) => ({
  credentials: getCredentials(),
  apiClient: createApiClient(),

  authenticate: (credentials: LocalCredentials) => {
    storeCredentials(credentials);

    set({ credentials });
  },
  logout: () => {
    removeCredentials();

    set({ credentials: undefined });
  },
}));
