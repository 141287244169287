import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Chip, TitleText } from '../../components/UI';
import { ShortOffer } from './types';
import styles from './Offers.module.scss';
import { OffersList } from './components/OffersList';

const offers: ShortOffer[] = [
  {
    id: '1',
    type: 'eCommerce',
    title: 'Sesja dla mybooker',
    city: 'Wrocław',
    country: 'PL',
    date: '14.07.2020',
    rate: 100,
  },
  {
    id: '2',
    type: 'eCommerce',
    title: 'Sesja dla mybooker',
    city: 'Wrocław',
    country: 'PL',
    date: '14.07.2020',
    rate: 100,
  },
  {
    id: '3',
    type: 'eCommerce',
    title: 'Sesja dla mybooker',
    city: 'Wrocław',
    country: 'PL',
    date: '14.07.2020',
    rate: 100,
  },
];

export const Offers = () => {
  const { formatMessage: f } = useIntl();
  const [isAllSelected, setIsAllSelected] = useState(true);

  const toggleSection = (isAll: boolean) => {
    setIsAllSelected(isAll);
  };

  return (
    <div className={styles.container}>
      <TitleText className={styles.title}>{ f({ id: 'offers.title' }) }</TitleText>
      <div className={styles.buttonsContainer}>
        <Chip
          chipStyle={styles.button}
          clicked={isAllSelected}
          onClick={() => toggleSection(true)}
        >
          { f({ id: 'offers.all' }) }
        </Chip>
        <Chip
          chipStyle={styles.button}
          clicked={!isAllSelected}
          onClick={() => toggleSection(false)}
        >
          { f({ id: 'offers.my' }) }
        </Chip>
      </div>
      { isAllSelected ?
        <OffersList offers={offers} /> :
        <OffersList offers={[]} myOffers /> }
    </div>
  );
};
