import { useState } from 'react';

export const useModal = () => {
  const [isShown, setIsShown] = useState(false);

  const handleOpen = () => {
    setIsShown(true);
  };

  const handleClose = () => {
    setIsShown(false);
  };

  const handleToggle = () => {
    setIsShown((prevState) => !prevState);
  };

  return {
    isShown, handleOpen, handleClose, handleToggle,
  };
};
