import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { TitleText } from '../../../components/UI';
import { useCreditCardSchema } from '../../../core/schemas';
import { CreditCardInput } from '../../addPayment/components';
import styles from './PaymentSettings.module.scss';

type Props = {
  isMobileView: boolean;
};

export const PaymentSettings = ({ isMobileView }: Props) => {
  const { formatMessage: f } = useIntl();
  const { initialValues, schema } = useCreditCardSchema();

  return (
    <div className={styles.container}>
      { isMobileView && <TitleText>{ f({ id: 'settings.payment' }) }</TitleText> }
      <Formik
        component={CreditCardInput}
        onSubmit={(values) => console.log(values)}
        initialValues={initialValues}
        validationSchema={schema}
      />
    </div>
  );
};
