import { useField } from 'formik';
import { ChangeEvent, ComponentPropsWithoutRef } from 'react';
import { useIntl } from 'react-intl';
import { ErrorText } from '../../Text';
import styles from './LabelTextArea.module.scss';

interface Props extends ComponentPropsWithoutRef<'textarea'> {
  name: string
  form: string
}

export const LabelTextArea = ({ name, form, ...restProps }: Props) => {
  const { formatMessage: f } = useIntl();
  const [field, meta, helpers] = useField(name);

  const formCtx = {
    error: meta.error,
    touched: meta.touched,
    props: {
      onChange: (event: ChangeEvent<HTMLTextAreaElement>) => helpers.setValue(event.target.value),
      onBlur: () => helpers.setTouched(true),
      value: field.value,
    },
  };

  const shouldShowError = formCtx.error && formCtx.touched;

  return (
    <div className={styles.container}>
      <label className={styles.label}>
        { f({ id: `${form}.${name}.label` }) }
      </label>
      <textarea
        className={`${styles.textarea} ${
          shouldShowError && styles.errorBorder
        }`}
        placeholder={f({ id: `${form}.${name}.placeholder` })}
        rows={5}
        {...formCtx.props}
        {...restProps}
      />
      { shouldShowError && <ErrorText>{ formCtx.error }</ErrorText> }
    </div>
  );
};
