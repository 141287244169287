import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './LoadingScreen.module.scss';

type SpinnerProps = {
  size?: number
}

export const Spinner = ({ size = 50 }: SpinnerProps) => {
  return (
    <Stack sx={{ color: '#505050' }}>
      <CircularProgress color="inherit" size={size} />
    </Stack>
  );
};

export const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  );
};

export const LoadingComponent = () => {
  return (
    <div className={styles.component}>
      <Spinner />
    </div>
  );
};
