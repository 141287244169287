import { MdOutlineNoPhotography } from 'react-icons/md';
import styles from './ProfilePhoto.module.scss';

type Props = {
  profilePhoto: string | null | undefined
}

export const ProfilePhoto = ({ profilePhoto }: Props) => {
  if (!profilePhoto) {
    return (
      <div className={styles.center}>
        <MdOutlineNoPhotography size={100} color="#505050" />
      </div>
    );
  }

  return (
    <img
      src={profilePhoto}
      alt="model profile"
      className={styles.profileImg}
    />
  );
};
