import { useField } from 'formik';
import { ChangeEvent, MutableRefObject, useRef } from 'react';
import { useIntl } from 'react-intl';
import { getTextWidth } from '../../../../core/helpers';
import styles from './Measurements.module.scss';

type Props = {
  name: string
  unit?: string
  maxDigits?: number
  min?: number
  max?: number
  disabled?: boolean
}
const font = '16px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export const MeasurementInput = ({
  name,
  unit,
  maxDigits = 3,
  min = 50,
  max = 250,
  disabled = false,
}: Props) => {
  const [field, meta] = useField(name);
  const { formatMessage: f } = useIntl();
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const valueWidth = getTextWidth(field.value, font);

  const handleFocus = () => {
    inputRef.current.focus();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.toString().length <= maxDigits) {
      field.onChange(event);
    }
  };

  const isError = !!(meta.touched && meta.error);

  return (
    <div className={styles.measurementItem}>
      <span className={styles.measurementLabel}>
        { f({ id: `measurements.${name}` }) }
        :
      </span>
      <button type="button" className={styles.inputContainer} onClick={handleFocus}>
        <input
          ref={inputRef}
          type="number"
          min={min}
          max={max}
          name={name}
          value={field.value}
          onChange={handleChange}
          className={`
            ${styles.measurementValue}
            ${disabled && styles.measurementValueDisabled}
            ${isError && styles.error} 
          `}
          disabled={disabled}
        />
        { field.value > 0 && <span className={styles.inputUnitSuffix} style={{ left: `${Number(valueWidth) + 10}px` }}>{ unit }</span> }
      </button>
    </div>
  );
};
