// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useUser } from '../providers/User';

export const useMeasurementsSchema = () => {
  const { formatMessage: f } = useIntl();
  const { userD } = useUser();

  const initialValues = {
    cupSize: userD.profile.cupSize,
    dressSize: userD.profile.dressSize,
    eyeColor: userD.profile.eyeColor,
    hairColor: userD.profile.hairColor,
    height: userD.profile.height,
    weight: userD.profile.weight,
    hips: userD.profile.hips,
    shoeSize: userD.profile.shoeSize,
    waist: userD.profile.waist,
  };

  const schema = Yup.object().shape({
    height: Yup.number().min(100).required(f({ id: 'validationErrors.required' })),
    weight: Yup.number().min(30).required(f({ id: 'validationErrors.required' })),
    dressSize: Yup.string().required(f({ id: 'validationErrors.required' })),
    cupSize: Yup.string().required(f({ id: 'validationErrors.required' })),
    waist: Yup.number().min(30).required(f({ id: 'validationErrors.required' })),
    hips: Yup.number().min(30).required(f({ id: 'validationErrors.required' })),
    eyeColor: Yup.string().required(f({ id: 'validationErrors.required' })),
    hairColor: Yup.string().required(f({ id: 'validationErrors.required' })),
    shoeSize: Yup.number().min(20).required(f({ id: 'validationErrors.required' })),
  });

  return { initialValues, schema };
};
