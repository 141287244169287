import { useField } from 'formik';
import { ChangeEvent, ComponentPropsWithoutRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import styles from './TextInput.module.scss';

interface Props extends ComponentPropsWithoutRef<'input'> {
  name: string
  form: string
  containerStyle?: string
  disabled?: boolean
  type?: string
  value?: string
}

export const TextInput = ({
  name,
  form,
  containerStyle,
  disabled = false,
  type = 'text',
  value,
  ...restProps
}: Props) => {
  const { formatMessage: f } = useIntl();
  const [field, meta, helpers] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const [isShowed, setIsShowed] = useState(type !== 'password');

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.value);
  };
  const handleOnFocus = () => setIsFocused(true);
  const handleOnBlur = () => {
    helpers.setTouched(true);
    setIsFocused(false);
  };

  const handleOnShowPassword = () => {
    setIsShowed((prevState) => !prevState);
  };

  const formCtx = {
    error: meta.error,
    touched: meta.touched,
    props: {
      onChange: handleOnChange,
      onBlur: handleOnBlur,
      onFocus: handleOnFocus,
      value: value || field.value,
    },
  };

  const isPassword = type === 'password';

  const isLabelTop = isFocused || field.value.length > 0;

  const isError = formCtx.error && formCtx.touched;

  const inputType = isPassword ? (isShowed ? 'text' : type) : type;

  return (
    <div className={`${styles.outerContainer} ${containerStyle}`}>
      <div className={`${styles.container} ${isError && styles.errorBorder}`}>
        <input
          {...restProps}
          type={inputType}
          className={styles.input}
          disabled={disabled}
          {...formCtx.props}
        />
        <label
          className={`${styles.label}
        ${isLabelTop && styles.labelTop}
        ${isError && styles.errorLabel}`}
        >
          { f({ id: `${form}.fields.${name}` }) }
        </label>

        { isPassword && (
          <button type="button" className={styles.eyeIcon} onClick={handleOnShowPassword}>
            { isShowed ? (
              <AiOutlineEyeInvisible
                size={20}
                color={isError ? '#ff4747' : '#808080'}
              />
            ) : (
              <AiOutlineEye size={20} color={isError ? '#ff4747' : '#808080'} />
            ) }
          </button>
        ) }
      </div>

      { isError && <p className={styles.errorMessage}>{ formCtx.error }</p> }
    </div>
  );
};
