import { useState } from 'react';
import { toast } from 'react-toastify';
import { ChatBox, ChatList } from './components';
import { Chat } from './types';
import styles from './Messenger.module.scss';
import { useFetchBookingsQuery, useFetchMessagesQuery } from '../../generated/graphql';
import { LoadingComponent } from '../../components/UI';

// TODO: Fetch this from the api
export const chats: Chat[] = [
  {
    id: '1',
    photo: new URL('../../assets/modelProfile/modelProfile1.jpg', import.meta.url).href,
    name: 'Karolina Kielan',
    date: (new Date).setHours(11, 43),
    title: 'Propozycja pracy dla MyBooker',
    lastMessage: 'Hej! Piszę do Ciebie w związku z naszym ostatnim telefonem.',
  },
  {
    id: '2',
    photo: new URL('../../assets/modelProfile/modelProfile2.jpg', import.meta.url).href,
    name: 'Agata Kwiatkowska',
    date: (new Date).setHours(13, 53),
    title: 'Nowa kampania reklamowa firmy',
    lastMessage: 'Witam! Mam dla Pani ciekawą ofertę nowej sesji.',
  },
  {
    id: '3',
    photo: new URL('../../assets/modelProfile/modelProfile3.jpg', import.meta.url).href,
    name: 'Ewa Kościan',
    date: (new Date).setHours(9, 11),
    title: 'Otwarty konkurs modelingu',
    lastMessage: 'Cześć! Może chcesz wziąc udział w nowym konkursie związanym z modelingiem?',
  },
  {
    id: '4',
    photo: new URL('../../assets/modelProfile/modelProfile4.jpg', import.meta.url).href,
    name: 'Aleksandra Kielan',
    date: (new Date).setHours(12, 53),
    title: 'Ciekawa propozycja pracy',
    lastMessage: 'Najlepsza praca jaką moglaś sobie zamarzyć! Wystarczy, że wyślesz sms o treści:',
  },
  {
    id: '5',
    photo: new URL('../../assets/modelProfile/modelProfile4.jpg', import.meta.url).href,
    name: 'Aleksandra Kielan',
    date: (new Date).setHours(12, 53),
    title: 'Ciekawa propozycja pracy',
    lastMessage: 'Najlepsza praca jaką moglaś sobie zamarzyć! Wystarczy, że wyślesz sms o treści:',
  },
];

export const Messenger = () => {
  const [selectedChatId, setSelectedChatId] = useState<string>('');

  const handleChatChange = (id: string) => {
    setSelectedChatId(id);
  };

  const handleResetChat = () => {
    setSelectedChatId('');
  };

  const isChatBoxHidden = selectedChatId.length === 0;

  const { data: bookings, loading: bookingsLoading } = useFetchBookingsQuery({
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  const { data, loading, fetchMore } = useFetchMessagesQuery({
    variables: {
      bookingId: selectedChatId,
    },
    skip: isChatBoxHidden,
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  return (
    <div className={styles.container}>
      <div
        className={`${
          isChatBoxHidden ? styles.chatListActive : styles.chatListHidden
        } ${styles.transition} ${styles.chats}`}
      >
        { bookingsLoading ?
          <div className={styles.loadingContainer}><LoadingComponent /></div> :
          <ChatList chats={chats} handleChatChange={handleChatChange} /> }
      </div>
      <div
        className={`${
          isChatBoxHidden ? styles.chatBoxHidden : styles.chatBoxActive
        } ${styles.transition} ${styles.chat}`}
      >
        { loading ?
          <div className={styles.loadingContainer}><LoadingComponent /></div> :
          <ChatBox chatId={selectedChatId} handleBack={handleResetChat} msgs={data} /> }
      </div>
    </div>
  );
};
