import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type Props = {
  domain: [number, number],
  highlight: [number, number],
  data: number[]
}

export const BarChart = ({ data, domain, highlight }: Props) => {
  const counts: { [key: number]: number } = {};

  for (let i = 0; i < data.length; i += 1) counts[data[i]] = counts[data[i]] + 1 || 1;

  const barDataValues = [];
  for (let i = 0; i < domain[1]; i += 1) {
    barDataValues.push(counts[i] || 0);
  }

  const barData = {
    labels: barDataValues.map((val, i) => i),
    datasets: [
      {
        backgroundColor: barDataValues.map((val, i) => (i >= highlight[0] && i <= highlight[1] ? '#000000' : '#a5a5a5')),
        hoverBackgroundColor: '#868686',
        data: barDataValues,
      },
    ],
  };

  const options = {
    type: 'bar',
    legend: {
      display: false,
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Bar data={barData} options={options} />
    </div>
  );
};
