import {
  Children,
  cloneElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
  Range, RangeProps, ChipsContainer, ChipsContainerProps,
} from '../../../components/UI';
import { useOutsideDiv } from '../../../core/hooks';
import styles from './FiltersAndCategories.module.scss';

type Props = {
  name: string;
  children: ReactNode;
};

export const FilterItem = ({ name, children }: Props) => {
  const { formatMessage: f } = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  const childWithProp = Children.map(children, (child) => {
    const item = child as
      | ReactElement<PropsWithChildren<RangeProps>>
      | ReactElement<PropsWithChildren<ChipsContainerProps>>;

    if (item.type === Range || item.type === ChipsContainer) {
      return cloneElement(item, { handleCollapse });
    }

    return child;
  });

  const ref = useOutsideDiv(() => setIsExpanded(false));
  return (
    <div ref={ref} className={styles.filterItemContainer}>
      <button
        type="button"
        className={`${styles.filterItemTitle} ${
          isExpanded && styles.expandedTitle
        }`}
        onClick={handleExpand}
      >
        { f({ id: `discover.filters.${name}` }) }
      </button>
      { isExpanded && (
        <div className={styles.expandedContainer}>{ childWithProp }</div>
      ) }
    </div>
  );
};
