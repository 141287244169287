import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';

import Routes from './core/navigation/Routes';
import { Providers } from './core/providers';

const App = () => {
  return (
    <>
      <Router>
        <Providers>
          <Navbar />
          <Routes />
          <Footer />
        </Providers>
      </Router>
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default App;
