import { TitleText } from '../../components/UI';
import styles from './NotFound.module.scss';

export const NotFound = () => {
  return (
    <div className={styles.container}>
      <TitleText>Page Not Found</TitleText>
    </div>
  );
};
