import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { TitleText } from '../../components/UI';
import { BookingTypeEnum } from '../../generated/graphql';
import { QuestionCard } from './components';
import styles from './QuestionType.module.scss';

export const QuestionType = () => {
  const { formatMessage: f } = useIntl();
  const params = useParams();
  const navigate = useNavigate();

  const handleClick = (bookingType: string) => {
    navigate(`/new-offer/${params.modelId}/${bookingType}`);
  };

  return (
    <div className={styles.container}>
      <TitleText className={styles.titleText}>
        { f({ id: 'questionType.header' }) }
      </TitleText>
      <div className={styles.typesContainer}>
        <QuestionCard name="directBooking" onClick={() => handleClick(BookingTypeEnum.DirectBooking)} />
        <QuestionCard name="option" onClick={() => handleClick(BookingTypeEnum.Option)} />
        <QuestionCard name="casting" onClick={() => handleClick(BookingTypeEnum.Casting)} />
      </div>
    </div>
  );
};
