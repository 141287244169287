import { ComponentPropsWithoutRef } from 'react';
import styles from '../Range.module.scss';

interface Props extends ComponentPropsWithoutRef<'input'> {
  name: string
}

const RangeInput = ({ name, ...restProps }: Props) => {
  return (
    <div className={styles.rangeInputContainer}>
      <label className={styles.rangeInputLabel}>{ name }</label>
      <input
        type="number"
        min={0}
        className={styles.rangeInput}
        {...restProps}
      />
    </div>
  );
};

export default RangeInput;
