import { GrFacebookOption, GrYoutube, GrTwitter } from 'react-icons/gr';
import { ImInstagram } from 'react-icons/im';

import LinkItem from './components/LinkItem';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={styles.linksContainer}>
          <LinkItem name="aboutUs" />
          <div className={styles.linkItemDot} />
          <LinkItem name="gallery" />
          <div className={styles.linkItemDot} />
          <LinkItem name="discover" />
          <div className={styles.linkItemDot} />
          <LinkItem name="login" />
          <div className={styles.linkItemDot} />
          <LinkItem name="contact" />
        </div>
        <div>
          <p className={styles.footerText}>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aspernatur
            similique aliquam sapiente veritatis natus ipsa minima, delectus
            saepe ipsam, facere laudantium, dolorum aut ex? Error, commodi
          </p>
          <div className={styles.socialLinksContainer}>
            <div className={`${styles.socialLink} ${styles.facebook}`}>
              <GrFacebookOption size={24} color="white" />
            </div>
            <div className={`${styles.socialLink} ${styles.instagram}`}>
              <ImInstagram size={24} color="white" />
            </div>
            <div className={`${styles.socialLink} ${styles.youtube}`}>
              <GrYoutube size={24} color="white" />
            </div>
            <div className={`${styles.socialLink} ${styles.twitter}`}>
              <GrTwitter size={24} color="white" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
