import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import valid from 'card-validator';

export const useCreditCardSchema = () => {
  const { formatMessage: f } = useIntl();

  const initialValues = {
    creditCardNumber: '',
    fullName: '',
    expirationDate: '',
    cvv: '',
  };

  const schema = Yup.object().shape({
    creditCardNumber: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .test(
        'test-number',
        'Credit card number is invalid',
        (value) => valid.number(value?.replace(/[-]/g, '')).isValid,
      ),

    fullName: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .test(
        'test-fullname',
        'Card holder name is invalid',
        (value) => valid.cardholderName(value).isValid,
      ),

    expirationDate: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .test(
        'test-expirationDate',
        'Expiration date is invalid',
        (value) => valid.expirationDate(value).isValid,
      ),

    cvv: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .test('test-cvv', 'CVV is invalid', (value) => valid.cvv(value).isValid),
  });

  return { initialValues, schema };
};
