import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import InputMask from 'react-input-mask';

import { Button, TitleText } from '../../../components/UI';
import { CreditCardTypes } from '../types';
import styles from './CreditCardInput.module.scss';

type Props = FormikProps<CreditCardTypes>;

export const CreditCardInput = ({
  submitForm,
  handleChange,
  values,
  errors,
  touched,
}: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <div className={styles.outerContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.container}>
          <TitleText className={styles.titleText}>
            { f({ id: 'addPayment.addPayment' }) }
          </TitleText>
          <InputMask
            mask="9999-9999-9999-9999"
            maskPlaceholder={null}
            className={`${styles.input} ${
              errors.creditCardNumber &&
              touched.creditCardNumber &&
              styles.errorBorder
            }`}
            placeholder={f({ id: 'addPayment.creditCardNumber' })}
            name="creditCardNumber"
            value={values.creditCardNumber}
            onChange={handleChange}
          />
          <input
            className={`${styles.input} ${
              errors.fullName && touched.fullName && styles.errorBorder
            }`}
            placeholder={f({ id: 'addPayment.fullName' })}
            name="fullName"
            value={values.fullName}
            onChange={handleChange}
          />
          <div className={styles.rowInputs}>
            <InputMask
              mask="99/99"
              maskPlaceholder={null}
              className={`${styles.input} ${styles.smallInput} ${
                errors.expirationDate &&
                touched.expirationDate &&
                styles.errorBorder
              }`}
              placeholder="MM/RR"
              name="expirationDate"
              value={values.expirationDate}
              onChange={handleChange}
            />
            <InputMask
              mask="999"
              maskPlaceholder={null}
              className={`${styles.input} ${styles.smallInput} ${
                errors.cvv && touched.cvv && styles.errorBorder
              }`}
              placeholder="CVV/CVC"
              name="cvv"
              value={values.cvv}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button buttonStyle={styles.anotherPayment}>
          { f({ id: 'addPayment.addAnotherPayment' }) }
        </Button>
      </div>
      <p className={styles.assuranceText}>
        { f({ id: 'addPayment.assurance' }) }
      </p>
      <Button buttonStyle={styles.submitButton} onClick={submitForm}>
        { f({ id: 'addPayment.submitButton' }) }
      </Button>
    </div>
  );
};
