import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { Checkbox, TextInput } from '../../../components/UI';
import styles from '../Register.module.scss';

type Props = {
  name: string;
};

export const RolesCheckbox = ({ name }: Props) => {
  const { formatMessage: f } = useIntl();
  const [field, meta, helpers] = useField(name);

  const handleSetField = (value: string) => {
    helpers.setValue(value);
  };

  const isError = !!meta.error && !!meta.touched;

  return (
    <div className={styles.rolesCheckboxContainer}>
      <TextInput
        name="accountType"
        form="register"
        containerStyle={styles.checkboxLabel}
        value={field.value && f({ id: `register.fields.accountType.${field.value.toLowerCase()}` })}
        disabled
      />
      <div className={styles.checkboxContainer}>
        <Checkbox
          isChecked={field.value === 'MODEL'}
          name="MODEL"
          label="Szukam pracy"
          onClick={handleSetField}
          isError={isError}
        />
        <Checkbox
          isChecked={field.value === 'CLIENT'}
          name="CLIENT"
          label="Oferuję pracę"
          onClick={handleSetField}
          isError={isError}
        />
      </div>
    </div>
  );
};
