import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Chip } from '../../../components/UI';
import { Filters, Categories } from '.';
import styles from './FiltersAndCategories.module.scss';

type Props = {
  handleSubmit: () => void;
};

export const FiltersAndCategories = ({ handleSubmit }: Props) => {
  const { formatMessage: f } = useIntl();
  const [expanded, setExpanded] = useState('');

  const handleOnClick = (value: string) => {
    if (expanded === value) {
      setExpanded('');
    } else {
      setExpanded(value);
    }
    handleSubmit();
  };

  return (
    <div>
      <div className={styles.rowButtons}>
        <Chip
          clicked={expanded === 'filters'}
          chipStyle={styles.button}
          onClick={() => handleOnClick('filters')}
        >
          { f({ id: 'discover.filters' }) }
        </Chip>
        <Chip
          clicked={expanded === 'categories'}
          chipStyle={styles.button}
          onClick={() => handleOnClick('categories')}
        >
          { f({ id: 'discover.categories' }) }
        </Chip>
      </div>

      { expanded === 'categories' && <Categories /> }

      { expanded === 'filters' && <Filters /> }
    </div>
  );
};
