import * as Yup from 'yup';
import { useIntl } from 'react-intl';

export interface LoginFormTypes {
  email: string;
  password: string;
}

export const useLoginSchema = () => {
  const { formatMessage: f } = useIntl();

  const initialValues = {
    email: '',
    password: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string().required(f({ id: 'validationErrors.required' })).email(f({ id: 'validationErrors.mustBeAnEmail' })),
    password: Yup.string().required(f({ id: 'validationErrors.required' })),
  });

  return { initialValues, schema };
};
