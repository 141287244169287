import { Formik } from 'formik';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import {
  Button, LabelInput, LoadingScreen, TitleText,
} from '../../../components/UI';
import { DatePicker } from '../../../components/UI/Input/DatePicker/DatePicker';
import { useUser } from '../../../core/providers/User';
import { useProfileSettingsSchema } from '../../../core/schemas';
import { useUpdateUserMutation } from '../../../generated/graphql';
import { ChangeEmail, ChangePassword, DeleteAccount } from './modals';
import styles from './ProfileSettings.module.scss';

type Props = {
  isMobileView: boolean;
};

export const ProfileSettings = ({ isMobileView }: Props) => {
  const { formatMessage: f } = useIntl();
  const { initialValues, schema } = useProfileSettingsSchema();
  const { user, setUser, refetchUser } = useUser();

  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: async () => {
      if (refetchUser) {
        await refetchUser();
      }
    },
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  return (
    <>
      { (loading) && <LoadingScreen /> }
      <div className={styles.container}>
        { isMobileView && <TitleText>{ f({ id: 'settings.profile' }) }</TitleText> }
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (data) => {
            const response = await updateUser({
              variables: {
                firstName: data.firstName,
                lastName: data.lastName,
              },
            });

            if (!response.errors && setUser) {
              setUser((prevState) => prevState && ({
                ...prevState,
                firstName: response.data?.userUpdate?.user.firstName,
                lastName: response.data?.userUpdate?.user.lastName,
              }));
            }
          }}
        >
          { ({ submitForm, values, isValid }) => (
            <>
              <div className={styles.form}>
                <div className={styles.rowInputs}>
                  <div className={styles.rowInput}>
                    <LabelInput name="firstName">
                      { f({ id: 'settings.profile.firstName' }) }
                    </LabelInput>
                  </div>
                  <div className={styles.rowInput}>
                    <LabelInput name="lastName">
                      { f({ id: 'settings.profile.lastName' }) }
                    </LabelInput>
                  </div>
                </div>
                <div className={styles.rowInputs}>
                  <div className={styles.rowInput}>
                    <DatePicker name="birthDate">
                      { f({ id: 'settings.profile.birthDate' }) }
                    </DatePicker>
                  </div>
                  <div className={styles.rowInput}>
                    <LabelInput name="phoneNumber" mask="999 999 999">
                      { f({ id: 'settings.profile.phoneNumber' }) }
                    </LabelInput>
                  </div>
                </div>
              </div>

              <div className={styles.changeContainer}>
                <div className={styles.row}>
                  <p className={styles.label}>
                    { f({ id: 'settings.profile.email' }) }
                  </p>
                  <ChangeEmail />
                </div>
                <div className={styles.rowDesktop}>
                  <p>{ f({ id: 'settings.profile.yourEmail' }) }</p>
                  <p className={styles.yourEmail}>{ user?.email }</p>
                </div>
              </div>

              <div className={styles.changeContainer}>
                <div className={styles.row}>
                  <p className={styles.label}>
                    { f({ id: 'settings.profile.password' }) }
                  </p>
                  <ChangePassword />
                </div>
              </div>

              <div className={styles.deleteContainer}>
                <DeleteAccount />
                <p className={styles.deleteAccountInfo}>
                  { f({ id: 'settings.profile.deleteAccountInfo' }) }
                </p>
              </div>

              <Button
                buttonStyle={styles.submitButton}
                onClick={() => {
                  if (!_.isEqual(values, initialValues)) {
                    submitForm();
                  }
                  if (isValid) {
                    // setIsLoading(true);
                  }
                }}
              >
                { f({ id: 'settings.profile.save' }) }
              </Button>
            </>
          ) }
        </Formik>
      </div>
    </>
  );
};
