import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AiOutlineLeft } from 'react-icons/ai';
import { TitleText } from '../../components/UI';
import { useWindowWidth } from '../../core/hooks';
import {
  LanguageSettings,
  PaymentSettings,
  ProfileSettings,
} from './components';
import { SettingsTypeButton } from './components/settingsTypeButton';
import styles from './Settings.module.scss';

const components = {
  profile: ProfileSettings,
  language: LanguageSettings,
  payment: PaymentSettings,
};

export type ComponentsKey = keyof typeof components;

export const Settings = () => {
  const { formatMessage: f } = useIntl();

  const isMobileView = useWindowWidth() < 992;
  const initialState = isMobileView ? null : 'profile';

  const [selectedSettings, setSelectedSettings] =
    useState<ComponentsKey | null>(initialState);

  const handleSettingsChange = (name: ComponentsKey) => {
    setSelectedSettings(name);
  };

  const Component = selectedSettings && components[selectedSettings];

  const firstActive = (isMobileView && !selectedSettings) || !isMobileView;
  const secondActive = (isMobileView && selectedSettings) || !isMobileView;

  return (
    <div className={`${styles.container}`}>
      { !isMobileView && (
        <div className={styles.titleContainer}>
          <TitleText>{ f({ id: 'settings' }) }</TitleText>
        </div>
      ) }
      <div className={styles.innerContainer}>
        { firstActive && (
          <div>
            { isMobileView && <TitleText>{ f({ id: 'settings' }) }</TitleText> }
            <div className={styles.settingsTypesContainer}>
              <SettingsTypeButton
                name="profile"
                handleClick={handleSettingsChange}
                active={selectedSettings === 'profile'}
              />
              <SettingsTypeButton
                name="language"
                handleClick={handleSettingsChange}
                active={selectedSettings === 'language'}
              />
              <SettingsTypeButton
                name="payment"
                handleClick={handleSettingsChange}
                active={selectedSettings === 'payment'}
              />
            </div>
          </div>
        ) }
        { secondActive && (
          <div className={`${styles.componentContainer}`}>
            { isMobileView && (
              <div className={styles.goBackButtonBox}>
                <AiOutlineLeft
                  className={styles.goBackButton}
                  onClick={() => setSelectedSettings(null)}
                />
              </div>
            ) }
            { Component && <Component isMobileView={isMobileView} /> }
          </div>
        ) }
      </div>
    </div>
  );
};
