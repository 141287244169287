import * as Yup from 'yup';
import { useIntl } from 'react-intl';

export const useChangePasswordSchema = () => {
  const { formatMessage: f } = useIntl();

  const checkCurrentPassword = async (password: string | undefined) => {
    if (!password) {
      return false;
    }

    try {
      // const {isPasswordCorrect} = await
      return true;
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const schema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .test(
        'check-current-password',
        f({ id: 'validationErrors.wrongPassword' }),
        (value) => checkCurrentPassword(value),
      ),
    newPassword: Yup.string().required(f({ id: 'validationErrors.required' })),
    newPasswordConfirmation: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .oneOf(
        [Yup.ref('password'), null],
        f({ id: 'validationErrors.passwordsMustMatch' }),
      ),
  });

  return { initialValues, schema };
};
