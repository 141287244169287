import { useField } from 'formik';
import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import InputMask from 'react-input-mask';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import styles from './LabelInput.module.scss';
import { ErrorText } from '../../Text';

interface Props extends ComponentPropsWithoutRef<'input'> {
  name: string
  form?: string
  containerStyle?: string
  suffix?: ReactNode
  noError?: boolean
  children?: ReactNode
  mask?: string
}

export const LabelInput = ({
  name,
  form,
  containerStyle,
  suffix,
  noError = false,
  children,
  mask,
  type = 'text',
  ...restProps
}: Props) => {
  const { formatMessage: f } = useIntl();

  const [field, meta, helpers] = useField(name);

  const [isShowed, setIsShowed] = useState(type !== 'password');

  const handleOnShowPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsShowed((prevState) => !prevState);
  };

  const formCtx = {
    error: meta.error,
    touched: meta.touched,
    props: {
      onChange: (event: ChangeEvent<HTMLInputElement>) => helpers.setValue(event.target.value),
      onBlur: () => helpers.setTouched(true),
      value: field.value,
    },
  };

  const isPassword = type === 'password';

  const inputType = isPassword ? (isShowed ? 'text' : type) : type;

  const shouldShowError = formCtx.error && formCtx.touched && !noError;

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <label className={styles.label}>
        { children || f({ id: `${form}.${name}.label` }) }
      </label>
      <div
        className={`${styles.inputContainer} ${
          shouldShowError && styles.errorBorder
        }`}
      >
        <InputMask
          mask={mask || ''}
          type={inputType}
          className={styles.input}
          placeholder={
            children ?
              (children as string) :
              f({ id: `${form}.${name}.placeholder` })
          }
          {...formCtx.props}
          {...restProps}
        />

        { isPassword && (
          <button type="button" className={styles.eyeIcon} onClick={handleOnShowPassword}>
            { isShowed ? (
              <AiOutlineEyeInvisible
                size={20}
                color={shouldShowError ? '#ff4747' : '#808080'}
              />
            ) : (
              <AiOutlineEye
                size={20}
                color={shouldShowError ? '#ff4747' : '#808080'}
              />
            ) }
          </button>
        ) }

        <div className={styles.suffix}>{ suffix }</div>
      </div>
      { shouldShowError && <ErrorText>{ formCtx.error }</ErrorText> }
    </div>
  );
};
