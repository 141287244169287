import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineInstagram } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import styles from './ModelProfile.module.scss';
import {
  Button, TitleText, LoadingScreen, ErrorScreen,
} from '../../components/UI';
import { formatCompactNumber } from '../../core/helpers';
import { Measurements, ModelBasicInformation, Portfolio } from './components';
import { FetchProfileQuery, useFetchProfileQuery } from '../../generated/graphql';
import { useUser } from '../../core/providers/User';
import { parseToBackendId } from '../../core/helpers/idParsers';
import { ProfilePhoto, FavButton } from '../../components/userProfile';

type Props = {
  myProfile?: boolean
}
export type ModelProfileType = Extract<FetchProfileQuery['profile'], { __typename: 'ModelProfile' }>;
type ProfileT = FetchProfileQuery['profile'];

type Params = { modelId: string };

export const ModelProfile = ({ myProfile = false }: Props) => {
  const { formatMessage: f } = useIntl();
  const { user } = useUser();
  const [isFav, setIsFav] = useState(false);
  const [apiError, setApiError] = useState('');

  const navigate = useNavigate();
  const params = useParams<Params>();

  const handleOnFav = () => {
    setIsFav((prevState) => !prevState);
  };

  const handleSendMessage = () => {
    navigate('/messenger');
  };

  const handleEmploy = () => {
    navigate(`/question-type/${params.modelId}`);
  };

  const { data, loading, error } = useFetchProfileQuery({
    variables: {
      profileId: (myProfile && user && user.profile) ? user.profile.id : parseToBackendId('model', params.modelId),
    },
    onError: ({ message }) => {
      setApiError(message);
      toast.error(message);
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data) {
    return <ErrorScreen errorMessage={apiError} />;
  }

  const isModelProfile = (profile: ProfileT): profile is ModelProfileType => {
    // eslint-disable-next-line no-underscore-dangle
    return profile.__typename === 'ModelProfile';
  };

  const { profile } = data;

  if (!isModelProfile(profile)) {
    toast.error('Coś poszło nie tak');
    return <Navigate to="/" />;
  }

  const displayedFollowersNumber =
    profile.followersCount && formatCompactNumber(profile?.followersCount);

  return (
    <div>
      <div className={styles.firstSection}>
        <div className={styles.profileImgContainer}>
          <ProfilePhoto profilePhoto={profile.profilePhoto} />
          <FavButton isFav={isFav} onClick={handleOnFav} />
        </div>

        <div className={styles.basicInfoContainer}>
          <TitleText
            className={`${styles.modelDetailsText} ${styles.modelName}`}
          >
            { profile.name }
          </TitleText>

          <div className={styles.modelDetailsContainer}>
            <ModelBasicInformation modelProfile={profile} />

            { displayedFollowersNumber && (
              <div className={styles.followersContainer}>
                <AiOutlineInstagram size={50} className={styles.instagramIcon} />
                <TitleText>{ displayedFollowersNumber }</TitleText>
              </div>
            ) }

            { !myProfile && (
              <div className={styles.buttonsContainer}>
                <Button onClick={handleSendMessage}>{ f({ id: 'modelProfile.sendMessage' }) }</Button>
                <Button onClick={handleEmploy}>{ f({ id: 'modelProfile.employ' }) }</Button>
              </div>
            ) }
          </div>
        </div>
      </div>

      <Measurements data={profile} myProfile={myProfile} />
      <Portfolio photos={profile.images} />
    </div>
  );
};
