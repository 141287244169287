import { ModelProfileType } from '../ModelProfile';
import styles from '../ModelProfile.module.scss';

type Props = {
  modelProfile: ModelProfileType
}

export const ModelBasicInformation = ({ modelProfile }: Props) => {
  return (
    <>
      <p className={`${styles.modelDetailsText} ${styles.modelLocation}`}>
        { modelProfile.location }
      </p>

      { /* { modelProfile.achievements.map((achievement) => (
        <p
          key={achievement}
          className={`${styles.modelDetailsText} ${styles.modelAchievement}`}
        >
          { achievement }
        </p>
      )) } */ }

      <p className={`
        ${styles.modelDetailsText} 
        ${false && styles.modelDescription}
        ${styles.modelAchievement}
        `}
      >
        { modelProfile.bio }
      </p>
    </>
  );
};
