import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';
import { useIntl } from 'react-intl';
import { ModelProfileType } from '../ModelProfile';
import styles from './Portfolio.module.scss';

const categories = ['all', 'portfolio', 'polaroid', 'video'] as const;

type Props = {
  photos: ModelProfileType['images']
}

export const Portfolio = ({ photos }: Props) => {
  const { formatMessage: f } = useIntl();
  const [selectedCategory, setSelectedCategory] = useState<typeof categories[number]>('all');
  const [displayedPhotos, setDisplayedPhotos] = useState(photos);
  const carouselRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleCategoryChange = (category: typeof categories[number]) => {
    setSelectedCategory(category);
    setDisplayedPhotos(photoFilter(category));
  };

  const photoFilter = (category: typeof categories[number]) => {
    if (category === 'all') {
      return photos;
    }
    return photos.filter((photo) => photo.type === category.toUpperCase());
  };

  useEffect(() => {
    carouselRef.current?.scroll({
      left: 0,
      behavior: 'smooth',
    });
  }, [selectedCategory]);

  return (
    <div className={styles.portfolioContainer}>
      <p className={styles.title}>Portfolio</p>
      <div className={styles.categoriesContainer}>
        { categories.map((category, index) => (
          <div className={styles.categoryContainer} key={category}>
            <button
              type="button"
              className={`${styles.category} ${
                category === selectedCategory && styles.active
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              { f({ id: `modelProfile.portfolio.${category}` }) }
            </button>
            { index + 1 < categories.length && <div className={styles.dot} /> }
          </div>
        )) }
      </div>

      <div ref={carouselRef} className={styles.carouselContainer}>
        { photos && displayedPhotos.map((photo) => (
          <div className={styles.carouselItem} key={photo.id}>
            <img src={photo.source} alt="" className={styles.carouselPhoto} />
          </div>
        )) }
      </div>
    </div>
  );
};
