import { FormikProps, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { GrLocation } from 'react-icons/gr';
import { useCallback, useEffect } from 'react';
import {
  Button,
  Checkbox,
  LabelInput,
  LabelTextArea,
} from '../../../components/UI';
import { NewOfferFormTypes } from '../../../core/schemas';
import styles from '../NewOffer.module.scss';
import { DatePicker } from '../../../components/UI/Input/DatePicker/DatePicker';
import { TimePicker } from '../../../components/UI/Input/TimePicker/TimePicker';
import { calculateTimeDifference } from '../../../core/helpers';

type Props = FormikProps<NewOfferFormTypes>;

export const NewOfferForm = ({ submitForm }: Props) => {
  const { formatMessage: f } = useIntl();
  const { values, errors, setFieldValue } =
    useFormikContext<NewOfferFormTypes>();

  useEffect(() => {
    if (
      !errors.hourStart &&
      !errors.hourEnd &&
      !!values.hourStart &&
      !!values.hourEnd) {
      const timeDifference = calculateTimeDifference(
        values.hourStart,
        values.hourEnd,
      );
      setFieldValue('time', timeDifference);
    } else {
      setFieldValue('time', '');
    }
  }, [
    values.hourStart,
    values.hourEnd,
    errors.hourStart,
    errors.hourEnd,
    setFieldValue,
  ]);

  const textFormat = useCallback(
    () => f(
      { id: 'newOffer.disclaimer' },
      {
        link: <span className={styles.link}>warunki regulaminu</span>,
      },
    ),
    [f],
  );

  return (
    <div className={styles.formContainer}>
      <div>
        <LabelInput form="newOffer" name="projectName" />
        <LabelInput form="newOffer" name="company" />
        <DatePicker
          form="newOffer"
          name="date"
          containerStyle={styles.smallInput}
          onlyFuture
        />
        <div className={styles.rowInputs}>
          <div className={styles.rowInput}>
            <TimePicker form="newOffer" name="hourStart" />
          </div>
          <div className={styles.rowInput}>
            <TimePicker form="newOffer" name="hourEnd" />
          </div>
        </div>
        <LabelInput
          form="newOffer"
          name="time"
          type="number"
          containerStyle={styles.smallInput}
          suffix="h"
          disabled
          noError
        />
        <LabelInput
          form="newOffer"
          name="location"
          containerStyle={styles.mediumInput}
          suffix={<GrLocation />}
        />
        <LabelInput
          form="newOffer"
          name="wage"
          type="number"
          suffix="PLN"
          containerStyle={styles.smallInput}
        />
        <LabelTextArea form="newOffer" name="description" />
        <LabelTextArea form="newOffer" name="rights" />
        <LabelTextArea form="newOffer" name="contraindications" />
      </div>
      <div className={styles.disclaimerContainer}>
        <Checkbox
          name="disclaimer"
          form="newOffer"
          isChecked={false}
          label={textFormat() as string}
          onClick={() => console.log('')}
        />
      </div>
      <Button buttonStyle={styles.submitButton} onClick={submitForm}>
        { f({ id: 'newOffer.submitButton' }) }
      </Button>
    </div>
  );
};
