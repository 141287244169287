import * as Yup from 'yup';
import { useIntl } from 'react-intl';

export const useChangeEmailSchema = () => {
  const { formatMessage: f } = useIntl();

  const initialValues = {
    email: '',
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .email(f({ id: 'validationErrors.mustBeAnEmail' })),
  });

  return { initialValues, schema };
};
