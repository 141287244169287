import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { getCredentials } from './credentials';

export const createApiClient = () => {
  // NOTE: createHttpLink's uri option defaults to /graphql, so there is no need to set it
  const httpLink = createHttpLink();

  const authLink = setContext((_, { headers }) => {
    const credentials = getCredentials();

    return {
      headers: {
        ...headers,
        'access-token': credentials?.accessToken,
        client: credentials?.client,
        uid: credentials?.uid,
      },
    };
  });

  return new ApolloClient({
    cache: new InMemoryCache,
    link: authLink.concat(httpLink),
  });
};
