import { ComponentPropsWithoutRef } from 'react';
import styles from './Button.module.scss';

interface Props extends ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  buttonStyle?: string;
  accent?: boolean;
  noHoverEffect?: boolean
  notifications?: number
}

export const Button = ({
  children,
  buttonStyle,
  accent = false,
  noHoverEffect = false,
  notifications,
  ...restProps
}: Props) => {
  return (
    <button
      type="button"
      className={`
      ${styles.button} 
      ${!noHoverEffect && styles.buttonHover}
      ${accent && styles.accent} ${buttonStyle}`}
      {...restProps}
    >
      { children }
      { notifications && <div className={styles.notifications}>{ notifications }</div> }
    </button>
  );
};
