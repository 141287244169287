import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import { useUser } from '../providers/User';

export const useProfileSettingsSchema = () => {
  const { formatMessage: f } = useIntl();
  const { userD } = useUser();

  const initialValues = {
    firstName: userD?.firstName,
    lastName: userD?.lastName,
    birthDate: '05/02/1997',
    phoneNumber: '695236173',
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().required(f({ id: 'validationErrors.required' })),
    lastName: Yup.string().required(f({ id: 'validationErrors.required' })),
    birthDate: Yup.string().required(f({ id: 'validationErrors.required' })),
    phoneNumber: Yup.string().required(f({ id: 'validationErrors.required' })),
  });

  return { initialValues, schema };
};
