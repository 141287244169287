import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { TitleText } from '../../components/UI';
import styles from './AddPayment.module.scss';
import { useCreditCardSchema } from '../../core/schemas';
import { CreditCardInput } from './components';

export const AddPayment = () => {
  const { formatMessage: f } = useIntl();
  const { initialValues, schema } = useCreditCardSchema();

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <TitleText className={styles.headerText}>
          { f({ id: 'addPayment.header' }) }
        </TitleText>
        <TitleText className={styles.headerText}>
          { f({ id: 'addPayment.why' }) }
        </TitleText>
        <p className={styles.textColor}>{ f({ id: 'addPayment.reason' }) }</p>
      </div>
      <div className={styles.item}>
        <Formik
          component={CreditCardInput}
          onSubmit={(values) => console.log(values)}
          initialValues={initialValues}
          validationSchema={schema}
        />
      </div>
    </div>
  );
};
