import { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';

import { useApplicationInternals } from '../hooks';

type Props = {
  children: ReactNode
}

export const Apollo = ({ children }: Props) => {
  const apiClient = useApplicationInternals((state) => state.apiClient);

  return (
    <ApolloProvider client={apiClient}>
      { children }
    </ApolloProvider>
  );
};
