import { useIntl } from 'react-intl';
import { BsEmojiSmile } from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { ChangeEvent, useState } from 'react';
import styles from './ChatInput.module.scss';
import { Message } from '../types';

type Props = {
  sendMessage: (message: Message) => void;
};

export const ChatInput = ({ sendMessage }: Props) => {
  const { formatMessage: f } = useIntl();
  const [message, setMessage] = useState('');
  const [isEmojiShown, setIsEmojiShown] = useState(false);

  const handleEmojiShown = () => {
    setIsEmojiShown((prevState) => !prevState);
  };

  const handleChangeMessage = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleAddEmoji = ({ native }: { native: string }) => {
    setMessage((prevState) => `${prevState} ${native}`);
  };

  const handleSubmit = () => {
    if (message.length === 0) {
      return;
    }

    const newMessage = {
      message,
      author: 'You',
      date: (new Date).toString(),
    };

    setIsEmojiShown(false);
    setMessage('');
    sendMessage(newMessage);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <TextareaAutosize
          className={styles.input}
          placeholder={f({ id: 'messenger.placeholder.sendMessage' })}
          maxRows={5}
          value={message}
          onChange={handleChangeMessage}
        />
        <BsEmojiSmile
          className={styles.suffix}
          onClick={handleEmojiShown}
          size={16}
        />
      </div>
      <button type="button" className={styles.sendButton}>
        <FiSend
          className={styles.sendButtonIcon}
          size={20}
          onClick={handleSubmit}
        />
      </button>
      { isEmojiShown && (
        <div className={styles.emojiContainer}>
          <Picker
            data={data}
            onEmojiSelect={handleAddEmoji}
            emojiButtonSize={28}
            emojiSize={20}
            previewPosition="none"
            skinTonePosition="none"
          />
        </div>
      ) }
    </div>
  );
};
