import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { CreateBookingMutationVariables } from '../../generated/graphql';
import { isDateValid, isTimeGreater, isTimeValid } from '../helpers';

export interface NewOfferFormTypes extends Omit<CreateBookingMutationVariables, 'profileId' | 'bookingType' > {
  disclaimer: boolean
}

export const useNewOfferSchema = () => {
  const { formatMessage: f } = useIntl();

  const initialValues: NewOfferFormTypes = {
    projectName: '',
    company: '',
    date: '',
    time: 0,
    hourStart: '',
    hourEnd: '',
    location: '',
    wage: 0,
    description: '',
    rights: '',
    contraindications: '',
    disclaimer: false,
  };

  const schema = Yup.object().shape({
    projectName: Yup.string().required(f({ id: 'validationErrors.required' })),

    company: Yup.string().required(f({ id: 'validationErrors.required' })),

    date: Yup.string().required(f({ id: 'validationErrors.required' })).test(
      'is-date-valid',
      f({ id: 'validationErrors.invalidDate' }),
      (value, ctx) => isDateValid(value, ctx),
    ),

    time: Yup.number()
      .typeError(f({ id: 'validationErrors.mustBeANumber' }))
      .transform((value) => (Number(value) === 0 ? null : value))
      .required(f({ id: 'validationErrors.required' })),

    hourStart: Yup.string().required(f({ id: 'validationErrors.required' })).test(
      'is-time-valid',
      f({ id: 'validationErrors.invalidTime' }),
      (value, ctx) => isTimeValid(value, ctx),
    ),

    hourEnd: Yup.string()
      .required(f({ id: 'validationErrors.required' })).test(
        'is-time-valid',
        f({ id: 'validationErrors.invalidTime' }),
        (value, ctx) => isTimeValid(value, ctx),
      )
      .test(
        'is-greater',
        f({ id: 'validationErrors.endTimeMustBeGreater' }),
        (value, ctx) => isTimeGreater(value, ctx),
      ),
    location: Yup.string().required(f({ id: 'validationErrors.required' })),

    wage: Yup.number()
      .typeError(f({ id: 'validationErrors.mustBeANumber' }))
      .transform((value) => (Number(value) === 0 ? undefined : value))
      .required(f({ id: 'validationErrors.required' })),

    description: Yup.string().required(f({ id: 'validationErrors.required' })),

    rights: Yup.string().required(f({ id: 'validationErrors.required' })),

    disclaimer: Yup.boolean()
      .required(f({ id: 'validationErrors.required' }))
      .oneOf([true], f({ id: 'validationErrors.required' })),
  });

  return { initialValues, schema };
};
