import { useIntl } from 'react-intl';
import { Button, TitleText } from '../../components/UI';
import { OfferDetails } from '../offer/components';
import styles from './CastingInvitation.module.scss';

const offer = {
  id: '1',
  type: 'eCommerce',
  title: 'Sesja dla mybooker',
  owner: 'MyBooker - mobile modeling agency',
  city: 'Wrocław',
  country: 'PL',
  date: '14.07.2020',
  startTime: '8:00',
  endTime: '10:00',
  address: 'ul. Sikorskiego 20/26',
  rate: 100,
  additionalInfo: 'Proszę zabrać ze sobą kurtkę zimową, gdyż będzie potrzebna do jednego ze zdjęćLorem ipsum dolor sit amet consectetur, adipisicing elit. A soluta delectus hic possimus numquam, harum autem reiciendis pariatur nobis dolorem est molestiae eius eligendi odio, voluptates deserunt accusamus ipsum, quidem perferendis esse vel omnis suscipit earum. Vel fugiat, eaque blanditiis, mollitia dolores perferendis nemo corporis cumque iusto illum enim accusantium voluptate vitae suscipit consectetur unde aspernatur, distinctio ipsum rem doloremque atque obcaecati ea possimus vero. Provident ullam at sit tempora, ratione, et odio obcaecati vero maxime iusto dicta quod placeat debitis aliquid minus earum sunt excepturi nam nisi modi? Cum autem nemo consequatur, vel excepturi accusantium omnis commodi quam soluta officia sapiente. Aut quaerat adipisci labore sunt eaque ipsa nulla esse officia tempora molestias dolores eligendi accusamus non totam, qui omnis nisi culpa enim quasi iure eius asperiores sequi cupiditate minima? Accusantium praesentium voluptas aut voluptatum qui expedita iure aliquam eligendi in, sed voluptate, ipsum ducimus rem consectetur tenetur autem voluptatem facere, quam impedit harum assumenda eaque libero nostrum! Consequatur accusamus maxime, rem similique fugit iste delectus, neque repellat ex, reprehenderit fugiat ipsum illum pariatur. Quo iusto illo ex omnis, quam impedit deserunt fuga molestias esse totam vitae quia velit consequatur expedita aperiam? Repudiandae possimus similique ipsam consectetur blanditiis iusto.',
};

export const CastingInvitation = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div className={styles.container}>
      <TitleText className={styles.textTitle}>Zaproszenie</TitleText>

      <p className={styles.invitationText}>{ f({ id: 'offers.casting_invitation' }) }</p>

      <div className={styles.detailColumn}>
        <p className={styles.detailLabel}>
          { f({ id: 'offers.offer_title' }) }
        </p>
        <p className={styles.detailText}>{ offer.title }</p>
      </div>

      <OfferDetails offer={offer} />

      <div className={styles.buttonsContainer}>
        <Button buttonStyle={styles.button}>
          { f({ id: 'offers.accept' }) }
        </Button>
        <Button buttonStyle={styles.button}>
          { f({ id: 'offers.refuse' }) }
        </Button>
        <Button buttonStyle={styles.button}>
          { f({ id: 'offers.send_message' }) }
        </Button>
      </div>
    </div>
  );
};
