import Rating from '@mui/material/Rating';

import { TitleText } from '../../../components/UI';
import styles from './Opinions.module.scss';

type Props = {
  opinion: {
    author: string;
    rating: number;
    opinion: string;
    img: string;
  };
};

export const OpinionItem = ({ opinion }: Props) => {
  return (
    <>
      <img src={opinion.img} alt="" className={styles.carouselPhoto} />
      <div className={styles.opinionData}>
        <TitleText className={styles.opinionAuthor}>{ opinion.author }</TitleText>
        <Rating
          value={opinion.rating}
          precision={0.1}
          sx={{ color: '#ffea00' }}
        />
        <p className={styles.opinionContent}>{ opinion.opinion }</p>
      </div>
    </>
  );
};
