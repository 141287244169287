import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import styles from './FavButton.module.scss';

type Props = {
  isFav: boolean,
  onClick: () => void
  black?: boolean
}

export const FavButton = ({ isFav, onClick, black = false }: Props) => {
  return (
    <button type="button" className={`${styles.favIconContainer} ${black && styles.blackBorder}`} onClick={onClick}>
      { isFav ? (
        <AiFillHeart color={black ? 'black' : 'white'} size={24} />
      ) : (
        <AiOutlineHeart color={black ? 'black' : 'white'} size={24} />
      ) }
    </button>
  );
};
