import { ReactNode } from 'react';

import { Intl } from './Intl';
import { Apollo } from './Apollo';
import { UserProvider } from './User';

type Props = {
  children: ReactNode
}

export const Providers = ({ children }: Props) => {
  return (
    <Apollo>
      <UserProvider>
        <Intl>
          { children }
        </Intl>
      </UserProvider>
    </Apollo>
  );
};
