import { useIntl } from 'react-intl';

import { ListItem } from '.';
import { ModelProfile } from '../../../generated/graphql';
import { TitleText } from '../../../components/UI';
import styles from './List.module.scss';

type Props = {
  data: (Pick<ModelProfile, 'id' | 'name' | 'profilePhoto'>)[] | undefined;
};

export const List = ({ data }: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <div className={styles.listContainer}>
      { (data && data?.length > 0) ? data.map((item) => (
        <ListItem item={item} key={item.id} />
      )) : (
        <TitleText>
          { f({ id: 'discover.noModelsAlert' }) }
        </TitleText>
      ) }
    </div>
  );
};
