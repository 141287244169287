import { ApolloQueryResult } from '@apollo/client';
import {
  createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { shallow } from 'zustand/shallow';
import { MeQuery, useMeLazyQuery } from '../../generated/graphql';

import { useApplicationInternals } from '../hooks';
import { LocalUser } from '../utils';

type UserResource = {
  user?: LocalUser;
  userD?: LocalUser;
  isLoggedIn: boolean;
  role: undefined | string;
  loading: boolean;

  refetchUser: (() => Promise<ApolloQueryResult<MeQuery>>) | undefined;
  setUser: (Dispatch<SetStateAction<LocalUser | undefined>>) | undefined
}

type ProviderProps = {
  children: ReactNode
}

const UserContext = createContext<UserResource>({
  user: undefined,
  userD: undefined,
  role: undefined,
  isLoggedIn: false,
  loading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refetchUser: undefined,
  setUser: undefined,
});

export const UserProvider = ({ children }: ProviderProps) => {
  const [isProviderLoading, setIsProviderLoading] = useState(true);
  const [userD, setUser] = useState<LocalUser | undefined>();

  const [credentials] = useApplicationInternals(
    (state) => [state.credentials],
    shallow,
  );
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [getUser, {
    data,
    refetch,
    loading,
    error,
    updateQuery,
  }] = useMeLazyQuery({
    onCompleted: (userData) => {
      if (pathname === '/login' || pathname === '/register') {
        navigate('/my-profile');
      }
      setUser(userData.me as LocalUser);
      setIsProviderLoading(false);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (credentials) {
      if (data) {
        refetch();
      } else {
        getUser();
      }
    } else {
      setIsProviderLoading(false);

      if (data) {
        updateQuery(() => ({ me: null }));
      }
    }
  }, [credentials]);

  const user = (!error && data?.me) ? data.me : undefined;

  const values = useMemo(() => ({
    user,
    userD,
    setUser,
    // eslint-disable-next-line no-underscore-dangle
    role: user?.profile?.__typename,
    isLoggedIn: !!user,
    loading: loading || isProviderLoading,
    refetchUser: refetch,
  }), [data, isProviderLoading]);

  return (
    <UserContext.Provider value={values}>
      { children }
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
