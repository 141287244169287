export const formatDateFromSlashToDash = (value: string) => {
  const data = value.split('/');
  const result = `${data[2]}-${data[1]}-${data[0]}`;
  const date = new Date(result);

  if (!Number.isNaN(date.getTime())) {
    return result;
  }

  return false;
};
