import { ComponentPropsWithoutRef, ReactNode } from 'react';

import styles from './Chip.module.scss';

interface Props extends ComponentPropsWithoutRef<'button'> {
  children: ReactNode;
  clicked: boolean;
  chipStyle?: string;
}

export const Chip = ({
  children,
  clicked = false,
  chipStyle,
  ...restProps
}: Props) => {
  return (
    <button
      type="button"
      className={`
    ${styles.button} 
    ${clicked && styles.clicked}
    ${chipStyle}`}
      {...restProps}
    >
      { children }
    </button>
  );
};
