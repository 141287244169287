// eslint-disable-next-line import/no-duplicates
import { formatDistanceToNowStrict } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { pl } from 'date-fns/locale';

import { Chat } from '../types';
import styles from './ChatItem.module.scss';

type Props = {
  chat: Chat
  onClick: () => void
}

export const ChatItem = ({ chat, onClick }: Props) => {
  const displayedTime = formatDistanceToNowStrict(new Date(chat.date), {
    locale: pl,
  });

  const displayedMessage = chat.lastMessage.length > 40 ? `${chat.lastMessage.slice(0, 39)}...` : chat.lastMessage;

  return (
    <button type="button" className={styles.container} onClick={onClick}>
      <div className={styles.profileThumbnailContainer}>
        <img
          className={styles.profileThumbnail}
          src={chat.photo}
          alt="Model profile thumbnail"
        />
      </div>
      <div className={styles.item}>
        <div className={styles.textRow}>
          <p className={styles.chatName}>{ chat.name }</p>
          <p className={styles.chatDate}>{ displayedTime }</p>
        </div>
        <p className={styles.chatTitle}>{ chat.title }</p>
        <p className={styles.chatMessage}>{ displayedMessage }</p>
      </div>
      <div className={`${styles.item} ${styles.fullMessage}`}>
        { chat.lastMessage }
      </div>
    </button>
  );
};
