import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import {
  Button, LabelInput, Modal, TitleText,
} from '../../../../components/UI';
import { useModal } from '../../../../core/hooks';
import { useChangePasswordSchema } from '../../../../core/schemas';
import styles from './Modals.module.scss';

export const ChangePassword = () => {
  const { formatMessage: f } = useIntl();
  const { initialValues, schema } = useChangePasswordSchema();
  const { isShown, handleOpen, handleClose } = useModal();

  return (
    <>
      <Modal isShown={isShown} handleClose={handleClose}>
        <TitleText className={styles.title}>
          { f({ id: 'settings.profile.changePassword' }) }
        </TitleText>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(data) => console.log(data)}
        >
          { ({ submitForm }) => (
            <>
              <LabelInput name="currentPassword" type="password">
                { f({ id: 'settings.profile.modals.currentPassword' }) }
              </LabelInput>
              <LabelInput name="newPassword" type="password">
                { f({ id: 'settings.profile.modals.newPassword' }) }
              </LabelInput>
              <LabelInput name="newPasswordConfirmation" type="password">
                { f({ id: 'settings.profile.modals.newPasswordConfirmation' }) }
              </LabelInput>
              <div className={styles.rowButtons}>
                <Button buttonStyle={styles.rowButton} onClick={handleClose}>
                  { f({ id: 'settings.profile.modals.cancel' }) }
                </Button>
                <Button buttonStyle={styles.rowButton} onClick={submitForm}>
                  { f({ id: 'settings.profile.modals.submit' }) }
                </Button>
              </div>
            </>
          ) }
        </Formik>
      </Modal>
      <Button buttonStyle={styles.changeButton} onClick={handleOpen}>
        { f({ id: 'settings.profile.changePassword' }) }
      </Button>
    </>
  );
};
