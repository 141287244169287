import { LocalCredentials } from './types';

export const getCredentials = (): LocalCredentials | undefined => {
  const accessToken = localStorage.getItem('access-token');
  const client = localStorage.getItem('client');
  const expiry = localStorage.getItem('expiry');
  const uid = localStorage.getItem('uid');
  // console.log(accessToken, client, expiry, uid);
  if (accessToken && client && expiry && uid) {
    return {
      accessToken,
      client,
      expiry: Number(expiry),
      uid,
    };
  }

  return undefined;
};

export const storeCredentials = (credentials: LocalCredentials) => {
  localStorage.setItem('access-token', credentials.accessToken);
  localStorage.setItem('client', credentials.client);
  localStorage.setItem('expiry', credentials.expiry.toString());
  localStorage.setItem('uid', credentials.uid);
};

export const removeCredentials = () => {
  ['access-token', 'client', 'expiry', 'uid'].map((key) => localStorage.removeItem(key));
};
