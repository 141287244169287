export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const context: CanvasRenderingContext2D | null = canvas.getContext('2d');

  let width = null;

  if (context) {
    context.font = font;
    width = context.measureText(text).width;
  }

  return width;
};
