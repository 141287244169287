import { ReactNode } from 'react';
import dotsImage from '~/images/dots.png';
import styles from './TextWithBg.module.scss';

type Props = {
  children: ReactNode;
  top?: number;
  left?: number;
  containerStyle?: string;
};

export const TextWithBg = ({
  children, top, left, containerStyle,
}: Props) => {
  return (
    <div className={`${styles.container} ${containerStyle}`}>
      { children }
      <img
        className={styles.helperImg}
        src={dotsImage}
        alt=""
        style={{ top: `${top}px`, left: `${left}px` }}
      />
    </div>
  );
};
