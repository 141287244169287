import { useIntl } from 'react-intl';
import { AiOutlineLeft } from 'react-icons/ai';
import { TbReportOff } from 'react-icons/tb';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import styles from './ChatBox.module.scss';
import { chats } from '../Messenger';
import { Chat, Message } from '../types';
import { ChatInput, MessageItem } from '.';
import { FetchMessagesQuery, useSendMessagesMutation } from '../../../generated/graphql';

type Props = {
  chatId: string
  msgs: FetchMessagesQuery | undefined
  handleBack: () => void
}

const messagesData: Message[] = [
  {
    message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae beatae. Odio asperiores rem molestias dignissimos aperiam a cupiditate in sit ex quia debitis, harum, ul quo voluptatibus ratione quibusdam nihil enim reprehenderit. Unde dolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'Someone',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae beatae. Odio asperiores rrum, ullam qui, suscipit quo voluptatibus ratione quibusdam nihil enim reprehenderit. Unde dolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'You',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem it. Unde dolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'Someone',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae beatae. Odio asperiores rem molestias dignissimos aperiam a cupiditate in sit ex quia ddolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'You',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem ipsum doonsectetur adipisicing elit. Earum, quae beatae. Odio asperiores rem molestias dignissimos aperiam a cupiditate in sit ex quia debitis, harum, ullam qui, suquo voluptatibus ratione quibusdam nihil enim reprehenderit. Unde dolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'You',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae beatae. Odio asperiores rem molestias dignissimos aperiam a cupiditate in sit ex quia debitis, harum, ullamolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'Someone',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae beatae. Odio asperiorestias?',
    author: 'You',
    date: (new Date).toString(),
  },
  {
    message: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, quae beatae. Odio asperiores rem molestias dignissimos aperiam a cupiditate in sit ex quia debitis, harum, ullam qui, suscipit quo voluptatibus ratione quibusdam nihil enim reprehenderit. Unde dolore incidunt sed quos illo est vitae eligendi! Ab ducimus harum inventore unde molestias?',
    author: 'Someone',
    date: (new Date).toString(),
  },
];

export const ChatBox = ({ chatId, msgs, handleBack }: Props) => {
  const { formatMessage: f } = useIntl();
  const [chat, setChat] = useState<Chat | undefined>(undefined);
  const [messages, setMessages] = useState<Message[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentChat = chats.find((item) => item.id === chatId);
    setMessages(messagesData);
    setChat(currentChat);
    ref.current?.scroll({
      top: ref.current?.scrollHeight,
      behavior: 'auto',
    });
  }, [chat, chatId]);

  useEffect(() => {
    ref.current?.scroll({
      top: ref.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  const [sendMessage] = useSendMessagesMutation({
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  const handleSendMessage = (message: Message) => {
    setMessages((prevState) => [...prevState, message]);
    sendMessage({
      variables: {
        messages: [message.message],
        bookingId: chat?.id || '',
      },
    });
  };

  const displayedDate = chat && format(new Date(chat.date), 'dd.MM.yyyy');
  const displayedTime = chat && format(new Date(chat.date), 'HH:MM');

  return (
    <>
      <div className={styles.header}>
        <AiOutlineLeft
          className={styles.backButton}
          onClick={handleBack}
          size={20}
        />
        { chat && (
          <>
            <div className={styles.profileThumbnailContainer}>
              <img
                className={styles.profileThumbnail}
                src={chat.photo}
                alt="Model profile thumbnail"
              />
            </div>
            <div className={styles.profileInfo}>
              <p>
                { f({ id: 'messenger.chat.title' }) }
                :
                { ' ' }
                <span className={styles.infoText}>{ chat.title }</span>
              </p>
              <p>
                { f({ id: 'messenger.chat.date' }) }
                :
                { ' ' }
                <span className={styles.infoText}>{ displayedDate }</span>
              </p>
              <p>
                { f({ id: 'messenger.chat.time' }) }
                :
                { ' ' }
                <span className={styles.infoText}>{ displayedTime }</span>
              </p>
            </div>
          </>
        ) }
      </div>
      <div className={`${styles.container}`}>
        { chat ? (
          <>
            <div ref={ref} className={styles.boxMessages}>
              { messages.map((message) => (
                <MessageItem message={message} key={message.date + message.author} />
              )) }
            </div>
            <ChatInput sendMessage={handleSendMessage} />
          </>
        ) : (
          <div className={styles.noChatSelected}>
            <TbReportOff className={styles.noChatIcon} />
            <p className={styles.noChatText}>
              { f({ id: 'messenger.noChatSelected' }) }
            </p>
          </div>
        ) }
      </div>
    </>
  );
};
