import { ChangeEvent, ReactNode, useState } from 'react';
import { useField } from 'formik';
import { GoCalendar } from 'react-icons/go';
import { useIntl } from 'react-intl';
import InputMask from 'react-input-mask';
import { format } from 'date-fns';
import styles from './DatePicker.module.scss';
import { ErrorText } from '../../Text';
import { formatDateFromSlashToDash } from '../../../../core/helpers';

type Props = {
  name: string
  form?: string
  containerStyle?: string
  children?: ReactNode
  onlyFuture?: boolean
}

export const DatePicker = ({
  name,
  form,
  containerStyle,
  children,
  onlyFuture = false,
}: Props) => {
  const { formatMessage: f } = useIntl();
  const [field, meta, helpers] = useField(name);
  const [datePickerValue, setDatePickerValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    helpers.setValue(value);

    const dashDate = formatDateFromSlashToDash(value);

    if (dashDate && !value.includes('_')) {
      setDatePickerValue(dashDate);
    }
  };

  const handleDatePickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setDatePickerValue(value);

    const formatted = format(new Date(value), 'dd/MM/yyyy');

    helpers.setValue(formatted);
  };

  const formCtx = {
    error: meta.error,
    touched: meta.touched,

    props: {
      onChange: handleInputChange,
      onBlur: () => helpers.setTouched(true),
      value: field.value,
    },
  };

  const today = format((new Date), 'yyyy-MM-dd');
  const shouldShowError = formCtx.error && formCtx.touched;

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <label className={styles.label}>
        { children || f({ id: `${form}.${name}.label` }) }
      </label>
      <div
        className={`${styles.inputContainer} ${
          shouldShowError && styles.errorBorder
        }`}
      >
        <InputMask
          type="text"
          mask="99/99/9999"
          className={styles.input}
          placeholder={
            children ?
              (children as string) :
              f({ id: `${form}.${name}.placeholder` })
          }
          {...formCtx.props}
        />
        <span className={styles.datepickerToggle}>
          <GoCalendar size={20} className={styles.datepickerToggleButton} />
          <input
            type="date"
            className={styles.datepickerInput}
            value={datePickerValue}
            onChange={handleDatePickerChange}
            min={onlyFuture ? today : undefined}
          />
        </span>
        { shouldShowError && <ErrorText bottom={-10}>{ formCtx.error }</ErrorText> }
      </div>
    </div>
  );
};
