import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Button,
  ErrorScreen,
  LoadingScreen,
} from '~/components/UI';
import { convertToGlobalId } from '~/core/helpers';
import { useFetchOfferQuery } from '~/generated/graphql';

import { OfferType } from './types';
import { OfferDetails } from './components';
import styles from './Offer.module.scss';
import offerJpg from '../../assets/offers/offer.jpg';

const offer: OfferType = {
  id: '1',
  type: 'eCommerce',
  title: 'Sesja dla mybooker',
  companyName: 'MyBooker - mobile modeling agency',
  city: 'Wrocław',
  country: 'PL',
  date: '14.07.2020',
  startHour: '8:00',
  endHour: '10:00',
  address: 'ul. Sikorskiego 20/26',
  rate: 100,
  additionalInfo: 'Proszę zabrać ze sobą kurtkę zimową, gdyż będzie potrzebna do jednego ze zdjęć',
};

type Params = { offerId: string };

export const Offer = () => {
  const params = useParams<Params>();
  const [apiError, setApiError] = useState('');

  // TODO: Set the error message
  // TODO: Maybe redirect to /offers
  if (!params.offerId) {
    return <ErrorScreen errorMessage="" />;
  }

  const {
    data,
    loading,
    error,
  } = useFetchOfferQuery({
    variables: {
      id: convertToGlobalId(params.offerId, 'Offer'),
    },
    onError: ({ message }) => {
      setApiError(message);
      toast.error(message);
    },
  });

  if (loading) {
    return <LoadingScreen />;
  }

  if (error || !data?.offer) {
    return <ErrorScreen errorMessage={apiError} />;
  }

  return (
    <div className={styles.container}>
      <img src={offerJpg} alt="Offer" className={styles.offerImg} />
      <div className={styles.innerContainer}>
        <div className={styles.applyContainer}>
          <div className={styles.applyInfoContainer}>
            <div className={styles.typeContainer}>
              { offer.type }
            </div>
            <p className={styles.offerTitle}>{ offer.title }</p>
          </div>
          <Button buttonStyle={styles.applyButton}>Aplikuj</Button>
        </div>
        <OfferDetails offer={data?.offer} />
      </div>
    </div>
  );
};
