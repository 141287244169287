import { useIntl } from 'react-intl';

// TODO: Move these to booking type icons or smth
import castingIcon from '~/images/questionTypeIcons/casting-icon.png';
import optionIcon from '~/images/questionTypeIcons/option-icon.png';
import directBookingIcon from '~/images/questionTypeIcons/directBooking-icon.png';
import styles from '../QuestionType.module.scss';
import { TitleText } from '../../../components/UI';

// TODO: Refactor this
const icons = {
  castingIcon,
  optionIcon,
  directBookingIcon,
};

type Props = {
  name: 'casting' | 'option' | 'directBooking';
  onClick: () => void
};

export const QuestionCard = ({ name, onClick }: Props) => {
  const { formatMessage: f } = useIntl();

  console.log(icons);

  return (
    <button type="button" className={styles.typeContainer} onClick={onClick}>
      <img
        src={icons[`${name}Icon`]}
        alt={`${name} icon`}
        className={styles.typeIcon}
      />
      <TitleText className={styles.typeTitle}>
        { f({ id: `questionType.${name}` }) }
      </TitleText>
      <p className={styles.typeDescription}>
        { f({ id: `questionType.${name}.description` }) }
      </p>
    </button>
  );
};
