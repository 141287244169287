export const calculateTimeDifference = (startTime: string, endTime: string) => {
  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');

  const start = (new Date).setHours(Number(startHours), Number(startMinutes));

  const end = (new Date).setHours(Number(endHours), Number(endMinutes));

  const difference = (end - start) / (1000 * 3600);

  return Number(difference.toFixed(2));
};
