import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import sideImage from '~/images/6.png';
import { Button, TextWithBg } from '../../../components/UI';
import sharedStyles from './sharedStyles.module.scss';

export const ClientSection = () => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/discover');
  };

  return (
    <section className={sharedStyles.sectionContainer}>
      <div className={sharedStyles.sectionItem}>
        <img
          className={sharedStyles.sectionImage}
          src={sideImage}
          alt=""
        />
      </div>
      <div
        className={`${sharedStyles.sectionItem} ${sharedStyles.sectionTextContainer}`}
      >
        <header>
          <TextWithBg>
            <p className={sharedStyles.headerText}>
              { f({ id: 'home.clientSection.profile' }) }
            </p>
            <p
              className={`${sharedStyles.headerText} ${sharedStyles.headerBoldText}`}
            >
              { f({ id: 'home.clientSection.client' }) }
            </p>
          </TextWithBg>
        </header>
        <div className={sharedStyles.sectionTextContainer}>
          <p className={sharedStyles.sectionText}>
            { f({ id: 'home.clientSection.paragraph.first' }) }
          </p>
          <p className={sharedStyles.sectionText}>
            { f({ id: 'home.clientSection.paragraph.second' }) }
          </p>
          <p className={sharedStyles.sectionText}>
            { f({ id: 'home.clientSection.paragraph.third' }) }
          </p>
        </div>
        <Button onClick={handleNavigate}>
          { f({ id: 'home.clientSection.seeMore' }) }
        </Button>
      </div>
    </section>
  );
};
