import { BsFillPersonFill, BsCreditCard } from 'react-icons/bs';
import { TbLanguage } from 'react-icons/tb';
import { AiOutlineRight } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { ComponentsKey } from '../../Settings';
import styles from './SettingsTypeButton.module.scss';

type Props = {
  name: ComponentsKey;
  handleClick: (name: ComponentsKey) => void;
  active: boolean;
};

const icons = {
  profile: BsFillPersonFill,
  language: TbLanguage,
  payment: BsCreditCard,
};

export const SettingsTypeButton = ({ name, handleClick, active }: Props) => {
  const { formatMessage: f } = useIntl();
  const Icon = icons[name as keyof typeof icons];

  return (
    <div
      className={`${styles.settingsTypeContainer} ${active && styles.active}`}
    >
      <button
        type="button"
        className={styles.settingsTypeButton}
        onClick={() => handleClick(name)}
      >
        <div>
          <Icon />
          <span>{ f({ id: `settings.${name}` }) }</span>
        </div>
        <AiOutlineRight className={styles.selectTypeIcon} />
      </button>
    </div>
  );
};
