import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { LoadingComponent, TitleText } from '../../components/UI';
import { useFetchModelProfilesQuery } from '../../generated/graphql';
import { FiltersAndCategories, List, SortBy } from './components';
import styles from './Discover.module.scss';

const initialValues = {
  sortBy: '',
  height: [0, 100],
  hips: [0, 100],
  waist: [0, 100],
  hairColor: [],
  eyeColor: [],
  shoeSize: [35, 45],
  categories: [],
};

export const Discover = () => {
  const {
    loading,
    data,
  } = useFetchModelProfilesQuery({
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        onSubmit={(filters) => console.log(filters)}
      >
        { ({ submitForm, handleSubmit }) => (
          <>
            <div className={styles.rowContainer}>
              <TitleText className={styles.title}>Modelki</TitleText>
              <SortBy handleSubmit={submitForm} />
            </div>
            <FiltersAndCategories handleSubmit={handleSubmit} />

            { loading ? <LoadingComponent /> : <List data={data?.models.nodes} /> }
          </>
        ) }
      </Formik>
    </div>
  );
};
