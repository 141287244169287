import { useState } from 'react';
import { Formik } from 'formik';
import _ from 'lodash';
import { MdOutlineEdit, MdOutlineEditOff } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useUser } from '../../../../core/providers/User';
import { useMeasurementsSchema } from '../../../../core/schemas/useMeasurementsSchema';
import { MeasurementInput } from './MeasurementInput';
import { MeasurementSelect } from './MeasurementSelect';
import styles from './Measurements.module.scss';
import { useUpdateModelProfileMutation } from '../../../../generated/graphql';
import { Spinner } from '../../../../components/UI';

export const MeasurementsForm = () => {
  const [isEditable, setIsEditable] = useState(false);
  const { initialValues, schema } = useMeasurementsSchema();
  const { refetchUser, setUser } = useUser();

  const toggleEditable = () => {
    setIsEditable((prevState) => !prevState);
  };

  const [updateData, { loading }] = useUpdateModelProfileMutation({
    onCompleted: async () => {
      if (refetchUser) {
        await refetchUser();
      }
    },
    onError: (apolloError) => {
      toast.error(apolloError.message);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const newData = await updateData({ variables: values });
        if (setUser) {
          setUser((prevState) => {
            // eslint-disable-next-line no-underscore-dangle
            if (prevState && prevState.profile?.__typename === 'ModelProfile') {
              return {
                ...prevState,
                profile: {
                  ...prevState.profile,
                  ...newData.data?.modelProfileUpdate?.modelProfile,
                },
              };
            }
            return undefined;
          });
        }
      }}
    >
      { ({ submitForm, values, isValid }) => (
        <>
          <button
            type="button"
            className={styles.editButton}
            onClick={() => {
              if (isEditable && !_.isEqual(values, initialValues)) {
                submitForm();
                if (isValid) {
                  toggleEditable();
                }
                return;
              }
              toggleEditable();
            }}
          >
            { isEditable ? <MdOutlineEditOff /> : <MdOutlineEdit /> }
          </button>
          <div className={styles.measurementsContainer}>
            { (loading) && (
              <div className={styles.formLoading}><Spinner /></div>
            ) }
            <MeasurementInput name="height" unit="cm" disabled={!isEditable} />
            <MeasurementInput name="weight" unit="kg" disabled={!isEditable} />
            <MeasurementSelect name="dressSize" disabled={!isEditable} />
            <MeasurementSelect name="cupSize" disabled={!isEditable} />
            <MeasurementInput name="waist" unit="cm" disabled={!isEditable} />
            <MeasurementInput name="hips" unit="cm" disabled={!isEditable} />
            <MeasurementSelect name="eyeColor" disabled={!isEditable} />
            <MeasurementSelect name="hairColor" disabled={!isEditable} />
            <MeasurementInput name="shoeSize" min={30} max={50} disabled={!isEditable} />
          </div>
        </>
      ) }
    </Formik>
  );
};
