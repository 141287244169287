import { ComponentPropsWithoutRef, ReactNode } from 'react';

import styles from './TitleText.module.scss';

interface Props extends ComponentPropsWithoutRef<'p'> {
  children: ReactNode;
  className?: string;
}

export const TitleText = ({ children, className, ...restProps }: Props) => {
  return (
    <header>
      <h1 className={`${styles.titleText} ${className}`} {...restProps}>
        { children }
      </h1>
    </header>
  );
};
