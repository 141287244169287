import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { AccountTypeEnum } from '../../generated/graphql';

export interface RegisterFormTypes {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  city: string;
  accountType: AccountTypeEnum;
  password: string;
  passwordConfirmation: string;
}

export const useRegisterSchema = () => {
  const { formatMessage: f } = useIntl();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    accountType: AccountTypeEnum.Model,
    password: '',
    passwordConfirmation: '',
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().required(f({ id: 'validationErrors.required' })),
    lastName: Yup.string().required(f({ id: 'validationErrors.required' })),
    email: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .email(f({ id: 'validationErrors.mustBeAnEmail' })),
    phoneNumber: Yup.string()
      .required(f({ id: 'validationErrors.required' })),
    city: Yup.string().required(f({ id: 'validationErrors.required' })),
    accountType: Yup.string().required(f({ id: 'validationErrors.required' })),
    password: Yup.string().required(f({ id: 'validationErrors.required' })),
    passwordConfirmation: Yup.string()
      .required(f({ id: 'validationErrors.required' }))
      .oneOf(
        [Yup.ref('password'), null],
        f({ id: 'validationErrors.passwordsMustMatch' }),
      ),
  });

  return { initialValues, schema };
};
