import { CompanyProfile, ModelProfile } from '../../pages';
import { useUser } from '../providers/User';

export const MyProfileRoute = () => {
  const { role } = useUser();
  const isModel = role === 'ModelProfile';

  if (isModel) {
    return <ModelProfile myProfile />;
  }

  return <CompanyProfile myProfile />;
};
