import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ShortOffer } from '../types';
import styles from './OfferItem.module.scss';

import offerJpg from '../../../assets/offers/offer.jpg';
import { Button } from '../../../components/UI';

type Props = {
  offer: ShortOffer
}

export const OfferItem = ({ offer }: Props) => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const handleApplyClick = () => {
    navigate(`/offer/${offer.id}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={offerJpg} alt="Offer" className={styles.offerImg} />
        <div className={styles.shadow} />
        <div className={styles.typeContainer}>
          { offer.type }
        </div>
        <Button
          buttonStyle={styles.applyButton}
          onClick={handleApplyClick}
          noHoverEffect
        >
          Aplikuj
        </Button>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsRow}>
          <p className={`${styles.detailsText} ${styles.titleText}`}>{ offer.title }</p>
          <p className={styles.detailsText}>{ offer.date }</p>
        </div>
        <div className={styles.detailsRow}>
          <p className={styles.detailsText}>
            { offer.city }
            ,
            { ' ' }
            { offer.country }
          </p>
          <p className={styles.detailsText}>
            { f({ id: 'offers.rate' }) }
            { ' ' }
            { offer.rate }
            { f({ id: 'offers.rate_unit' }) }

          </p>
        </div>
      </div>
    </div>
  );
};
