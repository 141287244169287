import { ClientProfileType } from '../CompanyProfile';
import styles from '../CompanyProfile.module.scss';

type Props = {
  companyProfile: ClientProfileType
};

export const CompanyBasicInfo = ({ companyProfile }: Props) => {
  return (
    <div className={styles.basicInfoInner}>
      { /* <p className={`${styles.detailsText} ${styles.location}`}>
        { companyData.city }
        ,
        { companyData.country }
      </p>

      { companyData.achievements.map((achievement) => (
        <p
          key={achievement}
          className={`${styles.detailsText} ${styles.achievement}`}
        >
          { achievement }
        </p>
      )) }

      <p className={`${styles.detailsText} ${styles.description}`}>
        { companyData.description }
      </p> */ }
    </div>
  );
};
