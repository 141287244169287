import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import sliderImage from '~/images/slider.jpg';
import { Button } from '../../../components/UI';
import styles from './Header.module.scss';
import sharedStyles from './sharedStyles.module.scss';

export const Header = () => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/register');
  };

  const textFormat = useCallback(
    () => f(
      { id: 'home.header.title' },
      {
        who: (
          <strong className={sharedStyles.headerBoldText}>
            Modelki z Klientami
          </strong>
        ),
      },
    ),
    [f],
  );

  return (
    <section className={styles.header}>
      <img className={styles.backgroundImage} src={sliderImage} alt="Model" />
      <header className={styles.headerTextContainer}>
        <p className={sharedStyles.headerText}>{ textFormat() }</p>
        <p className={styles.headerSubText}>
          { f({ id: 'home.header.subtitle' }) }
        </p>
        <Button buttonStyle={styles.registerButton} onClick={handleNavigate}>
          { f({ id: 'home.header.register' }) }
        </Button>
      </header>
    </section>
  );
};
