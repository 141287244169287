import { useState } from 'react';
import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';

import styles from '../Login.module.scss';
import { Button, TextInput, Checkbox } from '../../../components/UI';
import { LoginFormTypes } from '../../../core/schemas';

type Props = FormikProps<LoginFormTypes>;

export const LoginForm = ({ handleSubmit }: Props) => {
  const { formatMessage: f } = useIntl();
  const [isRemember, setIsRemember] = useState(false);

  const handleSetRemember = () => {
    setIsRemember((prevState) => !prevState);
  };

  return (
    <form className={styles.formContainer} onSubmit={handleSubmit} autoComplete="on">
      <TextInput name="email" form="login" type="email" />
      <TextInput name="password" form="login" type="password" />
      <Button type="submit" buttonStyle={styles.submitButton} accent>
        { f({ id: 'login.submitButton' }) }
      </Button>
      <div className={styles.checkboxContainer}>
        <Checkbox
          name="remember"
          label="Zapamiętaj mnie"
          isChecked={isRemember}
          onClick={handleSetRemember}
          size={20}
        />
      </div>
    </form>
  );
};
