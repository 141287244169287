import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { MdClose } from 'react-icons/md';
import { CSSTransition } from 'react-transition-group';
import { useOutsideDiv } from '../../../core/hooks';
import styles from './Modal.module.scss';

type ModalProps = {
  children: ReactNode
  isShown: boolean
  handleClose: () => void
  classContainer?: string
}

export const Modal = ({
  children,
  isShown,
  handleClose,
  classContainer,
}: ModalProps) => {
  const [isEntered, setIsEntered] = useState(false);
  const nodeRef = useRef(null);
  const ref = useOutsideDiv(handleClose);

  useEffect(() => {
    const closeOnEscapeKey = (e: globalThis.KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  const modalRef = isEntered ? ref : null;

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isShown}
      timeout={300}
      classNames="modal"
      onEnter={() => setIsEntered(false)}
      onEntered={() => setIsEntered(true)}
      unmountOnExit
    >
      <>
        { createPortal(
          <div ref={nodeRef} className={`modalWrapper ${styles.modalWrapper}`}>
            <div
              ref={modalRef}
              className={`modalContent ${styles.container} ${classContainer}`}
            >
              { children }
              <button
                type="button"
                className={styles.closeButtonContainer}
                onClick={handleClose}
              >
                <MdClose />
              </button>
            </div>
          </div>,
          document.body,
        ) }
      </>
    </CSSTransition>
  );
};
