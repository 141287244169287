import { AiFillHeart, AiOutlineInstagram } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, TitleText } from '../../../components/UI';
import { formatCompactNumber } from '../../../core/helpers';
import { parseToRouteId } from '../../../core/helpers/idParsers';
import { ModelProfile } from '../../../generated/graphql';
import styles from './List.module.scss';

type Props = {
  item: (Pick<ModelProfile, 'id' | 'name' | 'profilePhoto'>) | undefined;
};

export const ListItem = ({ item }: Props) => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const handleNavigateToProfile = () => {
    const userId = parseToRouteId(item?.id);
    navigate(`/model/${userId}`);
  };

  return (
    <div
      className={styles.listItem}
      style={{ backgroundImage: `url(${item?.profilePhoto})` }}
    >
      <div className={styles.shadow}>
        <div className={styles.itemInfoContainer}>
          <TitleText>{ item?.name }</TitleText>
          { /* <div className={styles.likesContainer}>
            <AiFillHeart color="white" size={16} />
            <p>{ formatCompactNumber(item.likes) }</p>
          </div> */ }
          { /* <div className={styles.likesContainer}>
            <AiOutlineInstagram color="white" size={16} />
            <p>{ formatCompactNumber(item.igFollowers) }</p>
          </div> */ }
          <Button
            buttonStyle={styles.redirectButton}
            onClick={handleNavigateToProfile}
          >
            { f({ id: 'discover.goToModelProfile' }) }
          </Button>
        </div>
      </div>
    </div>
  );
};
