import { useIntl } from 'react-intl';
import { Button, Modal, TitleText } from '../../../../components/UI';
import { useModal } from '../../../../core/hooks';
import styles from './Modals.module.scss';

export const DeleteAccount = () => {
  const { formatMessage: f } = useIntl();
  const { isShown, handleOpen, handleClose } = useModal();

  return (
    <>
      <Modal isShown={isShown} handleClose={handleClose}>
        <TitleText className={styles.title}>
          { f({ id: 'settings.profile.deleteAccount' }) }
        </TitleText>
        <div className={styles.textContainer}>
          <p>{ f({ id: 'settings.profile.modals.deleteAccountAsk' }) }</p>
        </div>
        <div className={styles.rowButtons}>
          <Button buttonStyle={styles.rowButton} onClick={handleClose}>
            { f({ id: 'settings.profile.modals.cancel' }) }
          </Button>
          <Button buttonStyle={styles.rowButton} onClick={() => { console.log('Deleted'); }}>
            { f({ id: 'settings.profile.modals.submit' }) }
          </Button>
        </div>
      </Modal>
      <Button buttonStyle={styles.changeButton} onClick={handleOpen}>
        { f({ id: 'settings.profile.deleteAccount' }) }
      </Button>
    </>
  );
};
