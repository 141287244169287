import { useState, useEffect, useCallback } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import logoImage from '~/images/logo.png';
import styles from './Navbar.module.scss';
import { useUser } from '../../core/providers/User';
import { useApplicationInternals } from '../../core/hooks';

const loggedInLinks = ['offers', 'messenger', 'myProfile'];
const notloggedInLinks = ['aboutUs', 'register', 'login'];
const createPath = (link: string) => `/${link.replace(/[A-Z]/g, (letter: string) => `-${letter.toLowerCase()}`)}`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useUser();
  const logout = useApplicationInternals((state) => state.logout);

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    document.body.style.position = isOpen ? 'fixed' : '';
  }, [isOpen]);

  const handleHomeNavigate = () => {
    navigate('/');
  };

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLogout = () => {
    navigate('/');
    logout();
  };

  const renderLinks = useCallback(() => {
    const links = isLoggedIn ? loggedInLinks : notloggedInLinks;

    return links.map((link) => (
      <NavLink key={link} to={createPath(link)} className={({ isActive }) => `${styles.navLink} ${isActive && styles.activeLink}`}>
        { f({ id: `navbarLinks.${link}` }) }
      </NavLink>
    ));
  }, [isLoggedIn]);

  return (
    <nav>
      <div className={styles.container}>
        <button type="button" onClick={handleHomeNavigate}>
          <img
            src={logoImage}
            alt="mybooker logo"
            className={styles.logo}
          />
        </button>

        <div className={styles.hamburgerMenu}>
          <button type="button" onClick={handleOpen} className={`${styles.hamburgerContainer}`}>
            <div
              className={`${styles.hamburgerIcon} ${isOpen && styles.open}`}
            />
          </button>
          <div
            className={`${styles.navbarContent} ${
              isOpen && styles.navbarContentActive
            }`}
          >
            { isOpen && (
              <div className={styles.navbarContentInner}>
                { renderLinks() }
                { isLoggedIn && <button type="button" onClick={handleLogout} className={`${styles.navLink} ${styles.logoutButton}`}>Wyloguj</button> }
              </div>
            ) }
          </div>
        </div>

        <div className={styles.desktopNavbar}>
          { renderLinks() }
          { isLoggedIn && <button type="button" onClick={handleLogout} className={`${styles.navLink} ${styles.logoutButton}`}>Wyloguj</button> }
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
