import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../providers/User';

type Props = {
  children: ReactElement
}

export const ProtectedRoute = ({ children }: Props) => {
  const { isLoggedIn } = useUser();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
