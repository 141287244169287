import { format } from 'date-fns';
import styles from './MessageItem.module.scss';

type Props = {
  message: {
    message: string;
    author: string;
    date: string;
  };
};

export const MessageItem = ({ message }: Props) => {
  const myMessageStyle = message.author === 'You' && styles.myMessageStyle;
  const date = new Date(message.date);
  const displayedTime = format(date, 'HH:MM');
  return (
    <div className={`${styles.container} ${myMessageStyle}`}>
      <p className={styles.message}>{ message.message }</p>
      <span className={styles.time}>{ displayedTime }</span>
    </div>
  );
};
